<!-- breadcrumb-area start -->
<div class="breadcrumb-area">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="breadcrumb_box text-center">
                    <h2 class="breadcrumb-title">Contact us</h2>
                    <!-- breadcrumb-list start -->
                    <ul class="breadcrumb-list">
                        <li [routerLink]="['/home']" class="breadcrumb-item"><a>Home</a></li>
                        <li class="breadcrumb-item active">Contact us </li>
                    </ul>
                    <!-- breadcrumb-list end -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- breadcrumb-area end -->

<div id="main-wrapper">
    <div class="site-wrapper-reveal">
        <!--====================  Conact us Section Start ====================-->
        <div class="contact-us-section-wrappaer section-space--pt_100 section-space--pb_70">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-lg-6">
                        <div class="conact-us-wrap-one mb-30">
                            <h3 class="heading">To make requests for <br>further information, <br><span class="text-color-primary">contact us</span> via our social channels. </h3>
                            <div class="sub-heading">We just need a couple of hours! <br> No more than 1 working days since receiving your ticket.</div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-lg-6">
                        <div class="contact-form-wrap">

                            <form #contact="ngForm" (ngSubmit)="contact_form(contact.value)">
                                <div class="contact-form">
                                    <p class="form-messege font-weight-bold" [ngClass]="status == '0' ? 'text-red' : 'text-green'">{{response_message}}</p>
                                    <div class="contact-input">
                                        <div class="contact-inner">
                                            <input [(ngModel)]="name" name="name" type="text" placeholder="Name *">
                                        </div>
                                        <div class="contact-inner">
                                            <input [(ngModel)]="email" name="email" type="email" placeholder="Email *">
                                        </div>
                                    </div>
                                    <div class="contact-inner">
                                        <input [(ngModel)]="subject" name="subject" type="text" placeholder="Subject *">
                                    </div>
                                    <div class="contact-inner contact-message">
                                        <textarea [(ngModel)]="discription" name="discription" placeholder="Please describe what you need."></textarea>
                                    </div>
                                    <div class="submit-btn mt-20">
                                        <button class="ht-btn ht-btn-md" type="submit">Send message</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--====================  Conact us Section End  ====================-->

        <!--====================  Conact us info Start ====================-->
        <div class="contact-us-info-wrappaer section-space--pb_100">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-4 col-md-6">
                        <div class="conact-info-wrap">
                            <h5 class="heading mb-20">India</h5>
                            <ul class="conact-info__list">
                                <li>905, Orbit-1 Business center , Punagam-Saroli Road, near Canal Corridor, Punagam, Surat, Gujarat, India - 395010</li>
                                <li><a href="mail:capsetinfotech@gmail.com" class="hover-style-link text-color-primary">capsetinfotech@gmail.com</a></li>
                                <li><a href="tel:9099310181" class="hover-style-link text-black font-weight--bold">+91(909)931-0181</a>
                                </li>
                                <!-- <li><a href="tel:9099310181" class="hover-style-link text-black font-weight--bold">+91(951)206-1005</a>
                                </li> -->
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-8 col-md-6">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3719.8568623979127!2d72.87913041526987!3d21.197844385907047!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04f9bde4874e1%3A0xc0fb046c1a955c51!2sOrbit%201%20by%20Pramukh!5e0!3m2!1sen!2sin!4v1641187552268!5m2!1sen!2sin" width="100%" height="230" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                        <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14877.872180251283!2d72.83496347963018!3d21.21328087437772!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04f15d3389b77%3A0xe030d20da82a4bd0!2sTwinnet%20Technologies!5e0!3m2!1sen!2sin!4v1612677370298!5m2!1sen!2sin"
                            width="100%" height="230" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe> -->
                        <!-- <div class="conact-info-wrap mt-30">
                            <h5 class="heading mb-20">India</h5>
                            <ul class="conact-info__list">
                                <li>58 Howard Street #14 New York</li>
                                <li><a href="#" class="hover-style-link text-color-primary">contact.newyork@mitech.com</a></li>
                                <li><a href="#" class="hover-style-link text-black font-weight--bold">(+47)1221 09878</a></li>
                            </ul>
                        </div> -->
                    </div>

                </div>
            </div>
        </div>
        <!--====================  Conact us info End  ====================-->






        <!--========== Call to Action Area Start ============-->
        <div class="cta-image-area_one section-space--ptb_80 cta-bg-image_two">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-xl-8 col-lg-7">
                        <div class="cta-content md-text-center">
                            <h3 class="heading">We run all kinds of <span class="text-color-primary"> IT services</span></h3>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-5">
                        <div class="cta-button-group--one text-center">
                            <a href="https://api.whatsapp.com/send?phone=919099310181&text=Hello Capset Infotech, I'm interested regarding IT services" target="_blank" class="btn btn--white btn-one"><span class="btn-icon mr-2"><i
                    class="far fa-comment-alt-dots"></i></span> Let's talk</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--========== Call to Action Area End ============-->

    </div>


</div>