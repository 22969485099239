<div class="breadcrumb-area">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="breadcrumb_box text-center">
                    <h2 class="breadcrumb-title">Technology</h2>
                    <!-- breadcrumb-list start -->
                    <ul class="breadcrumb-list">
                        <li class="breadcrumb-item"><a routerLink="/home" routerLinkActive="active">Home</a></li>
                        <li class="breadcrumb-item active">Technology </li>
                    </ul>
                    <!-- breadcrumb-list end -->
                </div>
            </div>
        </div>
    </div>
</div>

<div id="main-wrapper">
    <div class="site-wrapper-reveal">
        <!--===========  Projects wrapper Start =============-->
        <div class="projects-wrapper section-space--pb_70 section-space--pt_100 projects-masonary-wrapper">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="section-title-wrap text-center section-space--mb_40">
                            <h3 class="heading">The Technologies<span class="text-color-primary"> We Use </span></h3>
                        </div>

                        <div class="messonry-button text-center  section-space--mb_60">
                            <button data-filter="*" class="is-checked"><span class="filter-text">All</span><span
                  class="filter-counter">6</span></button>
                            <button data-filter=".cat--1"><span class="filter-text">Cloud Technology</span> <span
                  class="filter-counter">5</span></button>
                            <button data-filter=".cat--2"><span class="filter-text">Web / CMS Applications</span> <span
                  class="filter-counter">10</span></button>
                            <button data-filter=".cat--3"><span class="filter-text">Mobile Application</span> <span
                  class="filter-counter">4</span></button>
                            <button data-filter=".cat--4"><span class="filter-text">UI / UX</span> <span
                  class="filter-counter">4</span></button>
                        </div>
                    </div>
                </div>

                <div class="projects-case-wrap">
                    <div class="row mesonry-list">

                        <!--<div class="resizer"></div>-->
                        <div class="col-lg-4 col-md-6 cat--1">
                            <!-- Projects Wrap Start -->
                            <a href="#" class="projects-wrap style-2">
                                <div class="projects-image-box">
                                    <div class="projects-image img-tech-back">
                                        <img class="img-fluid img-tech-height" src="../../../capset_assets/images/technology/cloud-technology/aws-technology.png" alt="AWS">
                                    </div>
                                    <div class="content">
                                        <div class="post-categories">Cloud Technology</div>
                                        <h6 class="heading">AWS</h6>
                                    </div>
                                </div>
                            </a>
                            <!-- Projects Wrap End -->
                        </div>

                        <div class="col-lg-4 col-md-6 cat--1">
                            <!-- Projects Wrap Start -->
                            <a href="#" class="projects-wrap style-2">
                                <div class="projects-image-box">
                                    <div class="projects-image img-tech-back">
                                        <img class="img-fluid img-tech-height" src="../../../capset_assets/images/technology/cloud-technology/azure.png" alt="Azure">
                                    </div>
                                    <div class="content">
                                        <div class="post-categories">Cloud Technology</div>
                                        <h6 class="heading">Azure</h6>
                                    </div>
                                </div>
                            </a>
                            <!-- Projects Wrap End -->
                        </div>

                        <div class="col-lg-4 col-md-6 cat--1">
                            <!-- Projects Wrap Start -->
                            <a href="#" class="projects-wrap style-2">
                                <div class="projects-image-box">
                                    <div class="projects-image img-tech-back">
                                        <img class="img-fluid img-tech-height" src="../../../capset_assets/images/technology/cloud-technology/google_cloud_platform.png" alt="GDP">
                                    </div>
                                    <div class="content">
                                        <div class="post-categories">Cloud Technology</div>
                                        <h6 class="heading">GDP</h6>
                                    </div>
                                </div>
                            </a>
                            <!-- Projects Wrap End -->
                        </div>

                        <div class="col-lg-4 col-md-6 cat--1">
                            <!-- Projects Wrap Start -->
                            <a href="#" class="projects-wrap style-2">
                                <div class="projects-image-box">
                                    <div class="projects-image img-tech-back">
                                        <img class="img-fluid img-tech-height" src="../../../capset_assets/images/technology/cloud-technology/digital_ocean-technology.png" alt="Digital Ocean">
                                    </div>
                                    <div class="content">
                                        <div class="post-categories">Cloud Technology</div>
                                        <h6 class="heading">Digital Ocean</h6>
                                    </div>
                                </div>
                            </a>
                            <!-- Projects Wrap End -->
                        </div>

                        <div class="col-lg-4 col-md-6 cat--1">
                            <!-- Projects Wrap Start -->
                            <a href="#" class="projects-wrap style-2">
                                <div class="projects-image-box">
                                    <div class="projects-image img-tech-back">
                                        <img class="img-fluid img-tech-height" src="../../../capset_assets/images/technology/cloud-technology/ci-cd.png" alt="CI-CD">
                                    </div>
                                    <div class="content">
                                        <div class="post-categories">Cloud Technology</div>
                                        <h6 class="heading">CI-CD</h6>
                                    </div>
                                </div>
                            </a>
                            <!-- Projects Wrap End -->
                        </div>

                        <div class="col-lg-4 col-md-6 cat--2">
                            <!-- Projects Wrap Start -->
                            <a href="#" class="projects-wrap style-2">
                                <div class="projects-image-box">
                                    <div class="projects-image img-tech-back">
                                        <img class="img-fluid img-tech-height" src="../../../capset_assets/images/technology/custom-web-application/angular-technology.png" alt="Angular JS">
                                    </div>
                                    <div class="content">
                                        <div class="post-categories">Web Application</div>
                                        <h6 class="heading">Angular JS</h6>
                                    </div>
                                </div>
                            </a>
                            <!-- Projects Wrap End -->
                        </div>

                        <div class="col-lg-4 col-md-6 cat--2">
                            <!-- Projects Wrap Start -->
                            <a href="#" class="projects-wrap style-2">
                                <div class="projects-image-box">
                                    <div class="projects-image img-tech-back">
                                        <img class="img-fluid img-tech-height" src="../../../capset_assets/images/technology/custom-web-application/reactjs-technology.png" alt="React JS">
                                    </div>
                                    <div class="content">
                                        <div class="post-categories">Web Application</div>
                                        <h6 class="heading">React JS</h6>
                                    </div>
                                </div>
                            </a>
                            <!-- Projects Wrap End -->
                        </div>

                        <div class="col-lg-4 col-md-6 cat--2">
                            <!-- Projects Wrap Start -->
                            <a href="#" class="projects-wrap style-2">
                                <div class="projects-image-box">
                                    <div class="projects-image img-tech-back">
                                        <img class="img-fluid img-tech-height" src="../../../capset_assets/images/technology/custom-web-application/laravel-technology.png" alt="Laravel">
                                    </div>
                                    <div class="content">
                                        <div class="post-categories">Web Application</div>
                                        <h6 class="heading">Laravel</h6>
                                    </div>
                                </div>
                            </a>
                            <!-- Projects Wrap End -->
                        </div>

                        <div class="col-lg-4 col-md-6 cat--2">
                            <!-- Projects Wrap Start -->
                            <a href="#" class="projects-wrap style-2">
                                <div class="projects-image-box">
                                    <div class="projects-image img-tech-back">
                                        <img class="img-fluid img-tech-height" src="../../../capset_assets/images/technology/custom-web-application/codeigniter.png" alt="Codeigniter">
                                    </div>
                                    <div class="content">
                                        <div class="post-categories">Web Application</div>
                                        <h6 class="heading">Codeigniter</h6>
                                    </div>
                                </div>
                            </a>
                            <!-- Projects Wrap End -->
                        </div>

                        <div class="col-lg-4 col-md-6 cat--2">
                            <!-- Projects Wrap Start -->
                            <a href="#" class="projects-wrap style-2">
                                <div class="projects-image-box">
                                    <div class="projects-image img-tech-back">
                                        <img class="img-fluid img-tech-height" src="../../../capset_assets/images/technology/custom-web-application/php-technology.png" alt="PHP">
                                    </div>
                                    <div class="content">
                                        <div class="post-categories">Web Application</div>
                                        <h6 class="heading">PHP</h6>
                                    </div>
                                </div>
                            </a>
                            <!-- Projects Wrap End -->
                        </div>

                        <div class="col-lg-4 col-md-6 cat--2">
                            <!-- Projects Wrap Start -->
                            <a href="#" class="projects-wrap style-2">
                                <div class="projects-image-box">
                                    <div class="projects-image img-tech-back">
                                        <img class="img-fluid img-tech-height" src="../../../capset_assets/images/technology/custom-web-application/node_js.png" alt="Node JS">
                                    </div>
                                    <div class="content">
                                        <div class="post-categories">Web Application</div>
                                        <h6 class="heading">Node JS</h6>
                                    </div>
                                </div>
                            </a>
                            <!-- Projects Wrap End -->
                        </div>

                        <div class="col-lg-4 col-md-6 cat--2">
                            <!-- Projects Wrap Start -->
                            <a href="#" class="projects-wrap style-2">
                                <div class="projects-image-box">
                                    <div class="projects-image img-tech-back">
                                        <img class="img-fluid img-tech-height" src="../../../capset_assets/images/technology/custom-web-application/shopify-technology.png" alt="Shopify">
                                    </div>
                                    <div class="content">
                                        <div class="post-categories">CMS Application</div>
                                        <h6 class="heading">Shopify</h6>
                                    </div>
                                </div>
                            </a>
                            <!-- Projects Wrap End -->
                        </div>
                        <div class="col-lg-4 col-md-6 cat--2">
                            <!-- Projects Wrap Start -->
                            <a href="#" class="projects-wrap style-2">
                                <div class="projects-image-box">
                                    <div class="projects-image img-tech-back">
                                        <img class="img-fluid img-tech-height" src="../../../capset_assets/images/technology/custom-web-application/wordpress.png" alt="Wordpress">
                                    </div>
                                    <div class="content">
                                        <div class="post-categories">CMS Application</div>
                                        <h6 class="heading">Wordpress</h6>
                                    </div>
                                </div>
                            </a>
                            <!-- Projects Wrap End -->
                        </div>

                        <div class="col-lg-4 col-md-6 cat--2">
                            <!-- Projects Wrap Start -->
                            <a href="#" class="projects-wrap style-2">
                                <div class="projects-image-box">
                                    <div class="projects-image img-tech-back">
                                        <img class="img-fluid img-tech-height" src="../../../capset_assets/images/technology/custom-web-application/javascript-technology.png" alt="Javascript">
                                    </div>
                                    <div class="content">
                                        <div class="post-categories">Custom Web Application</div>
                                        <h6 class="heading">Javascript</h6>
                                    </div>
                                </div>
                            </a>
                            <!-- Projects Wrap End -->
                        </div>

                        <div class="col-lg-4 col-md-6 cat--2">
                            <!-- Projects Wrap Start -->
                            <a href="#" class="projects-wrap style-2">
                                <div class="projects-image-box">
                                    <div class="projects-image img-tech-back">
                                        <img class="img-fluid img-tech-height" src="../../../capset_assets/images/technology/custom-web-application/jquery.png" alt="Jquery">
                                    </div>
                                    <div class="content">
                                        <div class="post-categories">Custom Web Application</div>
                                        <h6 class="heading">Jquery</h6>
                                    </div>
                                </div>
                            </a>
                            <!-- Projects Wrap End -->
                        </div>

                        <div class="col-lg-4 col-md-6 cat--3">
                            <!-- Projects Wrap Start -->
                            <a href="#" class="projects-wrap style-2">
                                <div class="projects-image-box">
                                    <div class="projects-image img-tech-back">
                                        <img class="img-fluid img-tech-height" src="../../../capset_assets/images/technology/mobile application/android-technology.png" alt="Android">
                                    </div>
                                    <div class="content">
                                        <div class="post-categories">Mobile Application</div>
                                        <h6 class="heading">Android</h6>
                                    </div>
                                </div>
                            </a>
                            <!-- Projects Wrap End -->
                        </div>

                        <div class="col-lg-4 col-md-6 cat--3">
                            <!-- Projects Wrap Start -->
                            <a href="#" class="projects-wrap style-2">
                                <div class="projects-image-box">
                                    <div class="projects-image img-tech-back">
                                        <img class="img-fluid img-tech-height" src="../../../capset_assets/images/technology/mobile application/Swift_logo.png" alt="Swift">
                                    </div>
                                    <div class="content">
                                        <div class="post-categories">Mobile Application</div>
                                        <h6 class="heading">Swift (IOS)</h6>
                                    </div>
                                </div>
                            </a>
                            <!-- Projects Wrap End -->
                        </div>

                        <div class="col-lg-4 col-md-6 cat--3">
                            <!-- Projects Wrap Start -->
                            <a href="#" class="projects-wrap style-2">
                                <div class="projects-image-box">
                                    <div class="projects-image img-tech-back">
                                        <img class="img-fluid img-tech-height" src="../../../capset_assets/images/technology/mobile application/react-native.png" alt="React Native">
                                    </div>
                                    <div class="content">
                                        <div class="post-categories">Mobile Application</div>
                                        <h6 class="heading">React Native</h6>
                                    </div>
                                </div>
                            </a>
                            <!-- Projects Wrap End -->
                        </div>

                        <div class="col-lg-4 col-md-6 cat--3">
                            <!-- Projects Wrap Start -->
                            <a href="#" class="projects-wrap style-2">
                                <div class="projects-image-box">
                                    <div class="projects-image img-tech-back">
                                        <img class="img-fluid img-tech-height" src="../../../capset_assets/images/technology/mobile application/flutter.png" alt="Flutter">
                                    </div>
                                    <div class="content">
                                        <div class="post-categories">Mobile Application</div>
                                        <h6 class="heading">Flutter</h6>
                                    </div>
                                </div>
                            </a>
                            <!-- Projects Wrap End -->
                        </div>

                        <div class="col-lg-4 col-md-6 cat--4">
                            <!-- Projects Wrap Start -->
                            <a href="#" class="projects-wrap style-2">
                                <div class="projects-image-box">
                                    <div class="projects-image img-tech-back">
                                        <img class="img-fluid img-tech-height" src="../../../capset_assets/images/technology/ui-ux/photoshop.png" alt="Photoshop">
                                    </div>
                                    <div class="content">
                                        <div class="post-categories">UI / UX</div>
                                        <h6 class="heading">Photoshop</h6>
                                    </div>
                                </div>
                            </a>
                            <!-- Projects Wrap End -->
                        </div>

                        <div class="col-lg-4 col-md-6 cat--4">
                            <!-- Projects Wrap Start -->
                            <a href="#" class="projects-wrap style-2">
                                <div class="projects-image-box">
                                    <div class="projects-image img-tech-back">
                                        <img class="img-fluid img-tech-height" src="../../../capset_assets/images/technology/ui-ux/sketch.png" alt="Sketch">
                                    </div>
                                    <div class="content">
                                        <div class="post-categories">UI / UX</div>
                                        <h6 class="heading">Sketch</h6>
                                    </div>
                                </div>
                            </a>
                            <!-- Projects Wrap End -->
                        </div>

                        <div class="col-lg-4 col-md-6 cat--4">
                            <!-- Projects Wrap Start -->
                            <a href="#" class="projects-wrap style-2">
                                <div class="projects-image-box">
                                    <div class="projects-image img-tech-back">
                                        <img class="img-fluid img-tech-height" src="../../../capset_assets/images/technology/ui-ux/Adobe-xd.png" alt="Adobe XD">
                                    </div>
                                    <div class="content">
                                        <div class="post-categories">UI / UX</div>
                                        <h6 class="heading">Adobe XD</h6>
                                    </div>
                                </div>
                            </a>
                            <!-- Projects Wrap End -->
                        </div>

                        <div class="col-lg-4 col-md-6 cat--4">
                            <!-- Projects Wrap Start -->
                            <a href="#" class="projects-wrap style-2">
                                <div class="projects-image-box">
                                    <div class="projects-image img-tech-back">
                                        <img class="img-fluid img-tech-height" src="../../../capset_assets/images/technology/ui-ux/Figma.png" alt="Figma">
                                    </div>
                                    <div class="content">
                                        <div class="post-categories">UI / UX</div>
                                        <h6 class="heading">Figma </h6>
                                    </div>
                                </div>
                            </a>
                            <!-- Projects Wrap End -->
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <!--===========  Projects wrapper End =============-->










        <!--========== Call to Action Area Start ============-->
        <div class="cta-image-area_one section-space--ptb_80 cta-bg-image_one">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-xl-8 col-lg-7">
                        <div class="cta-content md-text-center">
                            <h3 class="heading text-white">We run all kinds of <span class="text-color-secondary"> IT services</span></h3>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-5">
                        <div class="cta-button-group--one text-center">
                            <a href="https://api.whatsapp.com/send?phone=919099310181&text=Hello Capset Infotech, I'm interested regarding IT services" target="_blank" class="btn btn--white btn-one"><span class="btn-icon mr-2"><i
                class="far fa-comment-alt-dots"></i></span> Let's talk</a>
                            <a [routerLink]="['/contact']" class="btn btn--secondary  btn-two"><span class="btn-icon mr-2"><i
                class="far fa-info-circle"></i></span> Get info</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--========== Call to Action Area End ============-->

    </div>










</div>