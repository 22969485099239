<div id="main-wrapper">
    <div class="site-wrapper-reveal">
        <!--============ Infotechno Hero Start ============-->
        <div class="infotechno-hero infotechno-bg">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <!--baseline-->
                    <div class="col-lg-6 col-md-6">
                        <div class="infotechno-hero-text  wow move-up">
                            <h6>Web and Mobile Apps Development Company </h6>
                            <h1 class="font-weight--reguler mb-15">Your Perfect <br/> Business Partner </h1>
                            <p>We add development capacity to tech teams. Our value isn’t limited to building teams but is equally distributed across the project lifecycle. We are a custom software & Mobile app development company that guarantees the successful delivery of your project.
                            </p>
                            <div class="hero-button  mt-30">
                                <a [routerLink]="['/contact']" class="ht-btn ht-btn-md">Get a Free Quote</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="infotechno-hero-inner-images">
                            <div class="infotechno-inner-one">
                                <img class="img-fluid" src="capset_assets/images/hero/home-infotechno-main-slider-slide-01-image-01.png" alt="">
                            </div>
                            <div class="infotechno-inner-two  wow move-up">
                                <img class="img-fluid" src="capset_assets/images/hero/home-infotechno-main-slider-slide-01-image-02.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--============ Infotechno Hero End ============-->
        <!--====================  brand logo slider area ====================-->
        <!-- <div class="brand-logo-slider-area section-space--ptb_60">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="brand-logo-slider__container-area">
                            <div class="swiper-container brand-logo-slider__container">
                                <div class="swiper-wrapper brand-logo-slider__one">
                                    <div class="swiper-slide brand-logo brand-logo--slider">
                                        <a href="#">
                                            <div class="brand-logo__image">
                                                <img src="capset_assets/images/brand/mitech-client-logo-01.png" class="img-fluid" alt="">
                                            </div>
                                            <div class="brand-logo__image-hover">
                                                <img src="capset_assets/images/brand/mitech-client-logo-01-hover.png" class="img-fluid" alt="">
                                            </div>
                                        </a>
                                    </div>
                                    <div class="swiper-slide brand-logo brand-logo--slider">
                                        <a href="#">
                                            <div class="brand-logo__image">
                                                <img src="capset_assets/images/brand/mitech-client-logo-02.png" class="img-fluid" alt="">
                                            </div>
                                            <div class="brand-logo__image-hover">
                                                <img src="capset_assets/images/brand/mitech-client-logo-02-hover.png" class="img-fluid" alt="">
                                            </div>
                                        </a>
                                    </div>
                                    <div class="swiper-slide brand-logo brand-logo--slider">
                                        <a href="#">
                                            <div class="brand-logo__image">
                                                <img src="capset_assets/images/brand/mitech-client-logo-03.png" class="img-fluid" alt="">
                                            </div>
                                            <div class="brand-logo__image-hover">
                                                <img src="capset_assets/images/brand/mitech-client-logo-03-hover.png" class="img-fluid" alt="">
                                            </div>
                                        </a>
                                    </div>
                                    <div class="swiper-slide brand-logo brand-logo--slider">
                                        <a href="#">
                                            <div class="brand-logo__image">
                                                <img src="capset_assets/images/brand/mitech-client-logo-04.png" class="img-fluid" alt="">
                                            </div>
                                            <div class="brand-logo__image-hover">
                                                <img src="capset_assets/images/brand/mitech-client-logo-04-hover.png" class="img-fluid" alt="">
                                            </div>
                                        </a>
                                    </div>
                                    <div class="swiper-slide brand-logo brand-logo--slider">
                                        <a href="#">
                                            <div class="brand-logo__image">
                                                <img src="capset_assets/images/brand/mitech-client-logo-05.png" class="img-fluid" alt="">
                                            </div>
                                            <div class="brand-logo__image-hover">
                                                <img src="capset_assets/images/brand/mitech-client-logo-05-hover.png" class="img-fluid" alt="">
                                            </div>
                                        </a>
                                    </div>
                                    <div class="swiper-slide brand-logo brand-logo--slider">
                                        <a href="#">
                                            <div class="brand-logo__image">
                                                <img src="capset_assets/images/brand/mitech-client-logo-06.png" class="img-fluid" alt="">
                                            </div>
                                            <div class="brand-logo__image-hover">
                                                <img src="capset_assets/images/brand/mitech-client-logo-06-hover.png" class="img-fluid" alt="">
                                            </div>
                                        </a>
                                    </div>
                                    <div class="swiper-slide brand-logo brand-logo--slider">
                                        <a href="#">
                                            <div class="brand-logo__image">
                                                <img src="capset_assets/images/brand/mitech-client-logo-07.png" class="img-fluid" alt="">
                                            </div>
                                            <div class="brand-logo__image-hover">
                                                <img src="capset_assets/images/brand/mitech-client-logo-07-hover.png" class="img-fluid" alt="">
                                            </div>
                                        </a>
                                    </div>
                                    <div class="swiper-slide brand-logo brand-logo--slider">
                                        <a href="#">
                                            <div class="brand-logo__image">
                                                <img src="capset_assets/images/brand/mitech-client-logo-08.png" class="img-fluid" alt="">
                                            </div>
                                            <div class="brand-logo__image-hover">
                                                <img src="capset_assets/images/brand/mitech-client-logo-08-hover.png" class="img-fluid" alt="">
                                            </div>
                                        </a>
                                    </div>
                                    <div class="swiper-slide brand-logo brand-logo--slider">
                                        <a href="#">
                                            <div class="brand-logo__image">
                                                <img src="capset_assets/images/brand/mitech-client-logo-09.png" class="img-fluid" alt="">
                                            </div>
                                            <div class="brand-logo__image-hover">
                                                <img src="capset_assets/images/brand/mitech-client-logo-09-hover.png" class="img-fluid" alt="">
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <!--====================  End of brand logo slider area  ====================-->
        <!--===========  feature-images-wrapper  Start =============-->
        <div class="feature-images-wrapper bg-gray section-space--ptb_100">
            <div class="container">

                <div class="row">
                    <div class="col-lg-12">
                        <!-- section-title-wrap Start -->
                        <div class="section-title-wrap text-center">
                            <h6 class="section-sub-title mb-20">Our services</h6>
                            <h3 class="heading">For your very specific industry, <br> we have <span class="text-color-primary">
                  highly-tailored IT solutions.</span></h3>
                        </div>
                        <!-- section-title-wrap Start -->
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="feature-images__one">
                            <div class="row">

                                <div class="col-lg-4 col-md-6 wow move-up">
                                    <!-- ht-box-icon Start -->
                                    <div class="ht-box-images style-01">
                                        <div class="image-box-wrap">
                                            <div class="box-image">
                                                <img class="img-fulid" src="capset_assets/images/icons/mitech-box-image-style-01-image-02-100x108.png" alt="">
                                            </div>
                                            <div class="content">
                                                <h5 class="heading">Web Developement </h5>
                                                <div class="text">We bring revolution to reality. Transforming your custom business ideas into an innovative next-gen web app is our forte. With proven results and promising qualitative deliverables across multiple tech markets.
                                                </div>
                                                <!-- <div class="circle-arrow">
                                                    <div class="middle-dot"></div>
                                                    <div class="middle-dot dot-2"></div>
                                                    <a routerLink="/home">
                                                        <i class="far fa-long-arrow-right"></i>
                                                    </a>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                    <!-- ht-box-icon End -->
                                </div>

                                <div class="col-lg-4 col-md-6 wow move-up">
                                    <!-- ht-box-icon Start -->
                                    <div class="ht-box-images style-01">
                                        <div class="image-box-wrap">
                                            <div class="box-image">
                                                <img class="img-fulid" src="capset_assets/images/icons/mitech-box-image-style-01-image-01-100x108.png" alt="">
                                            </div>
                                            <div class="content">
                                                <h5 class="heading">Mobile App Development</h5>
                                                <div class="text">The exponential increase in mobile users globally has made consumption of data quite extensive. It has also opened an opportunity for entrepreneurs to think and implement innovative business ideas.
                                                </div>
                                                <!-- <div class="circle-arrow">
                                                    <div class="middle-dot"></div>
                                                    <div class="middle-dot dot-2"></div>
                                                    <a routerLink="/home">
                                                        <i class="far fa-long-arrow-right"></i>
                                                    </a>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                    <!-- ht-box-icon End -->
                                </div>

                                <div class="col-lg-4 col-md-6 wow move-up">
                                    <!-- ht-box-icon Start -->
                                    <div class="ht-box-images style-01">
                                        <div class="image-box-wrap">
                                            <div class="box-image">
                                                <img class="img-fulid" src="capset_assets/images/icons/mitech-box-image-style-01-image-03-100x108.png" alt="">
                                            </div>
                                            <div class="content">
                                                <h5 class="heading">Software Development</h5>
                                                <div class="text">The custom software development process encompasses designing, developing, deploying, and maintaining custom software solutions and services. As a leading custom software development company.
                                                </div>
                                                <!-- <div class="circle-arrow">
                                                    <div class="middle-dot"></div>
                                                    <div class="middle-dot dot-2"></div>
                                                    <a routerLink="/home">
                                                        <i class="far fa-long-arrow-right"></i>
                                                    </a>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                    <!-- ht-box-icon End -->
                                </div>

                                <div class="col-lg-4 col-md-6 wow move-up">
                                    <!-- ht-box-icon Start -->
                                    <div class="ht-box-images style-01">
                                        <div class="image-box-wrap">
                                            <div class="box-image">
                                                <img class="img-fulid" src="capset_assets/images/icons/mitech-box-image-style-01-image-04-100x108.png" alt="">
                                            </div>
                                            <div class="content">
                                                <h5 class="heading">Digital Marketing</h5>
                                                <div class="text">We’re A Supportive Community Leading Change In Digital Project Management. We understand the importance of formulating the strategies and interpret the analytics followed by running an on-point ads campaign.
                                                </div>
                                                <!-- <div class="circle-arrow">
                                                    <div class="middle-dot"></div>
                                                    <div class="middle-dot dot-2"></div>
                                                    <a routerLink="/home">
                                                        <i class="far fa-long-arrow-right"></i>
                                                    </a>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                    <!-- ht-box-icon End -->
                                </div>

                                <div class="col-lg-4 col-md-6 wow move-up">
                                    <!-- ht-box-icon Start -->
                                    <div class="ht-box-images style-01">
                                        <div class="image-box-wrap">
                                            <div class="box-image">
                                                <img class="img-fulid" src="capset_assets/images/icons/mitech-box-image-style-01-image-05-100x108.png" alt="">
                                            </div>
                                            <div class="content">
                                                <h5 class="heading">UI/UX Design</h5>
                                                <div class="text">Explore innovative digital experience by offering a blend of technology, creativity and personalization in UI and UX development services. We design captivating user experience with a significant.
                                                </div>
                                                <!-- <div class="circle-arrow">
                                                    <div class="middle-dot"></div>
                                                    <div class="middle-dot dot-2"></div>
                                                    <a routerLink="/home">
                                                        <i class="far fa-long-arrow-right"></i>
                                                    </a>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                    <!-- ht-box-icon End -->
                                </div>

                                <div class="col-lg-4 col-md-6 wow move-up">
                                    <!-- ht-box-icon Start -->
                                    <div class="ht-box-images style-01">
                                        <div class="image-box-wrap">
                                            <div class="box-image">
                                                <img class="img-fulid" src="capset_assets/images/icons/mitech-box-image-style-01-image-06-100x108.png" alt="">
                                            </div>
                                            <div class="content">
                                                <h5 class="heading">Capset Digital Project</h5>
                                                <div class="text">To convert your vision into reality, we assist you from software product ideation to design, development, release, and maintenance. Every successful product is unique in its market and with its uniqueness.
                                                </div>
                                                <!-- <div class="circle-arrow">
                                                    <div class="middle-dot"></div>
                                                    <div class="middle-dot dot-2"></div>
                                                    <a routerLink="/home">
                                                        <i class="far fa-long-arrow-right"></i>
                                                    </a>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                    <!-- ht-box-icon End -->
                                </div>

                            </div>
                        </div>

                        <!-- <div class="section-under-heading text-center section-space--mt_60 section-space--pt_30">Challenges are just opportunities in disguise. <a href="#">Take the challenge!</a></div> -->

                    </div>
                </div>
            </div>
        </div>
        <!--===========  feature-images-wrapper  End =============-->

        <!-- ============ Our Experience Wrapper Start =============== -->
        <div class="section-space--ptb_100 infotechno-section-bg-01">

            <div class="our-experience-wrapper">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-10">
                            <div class="section-title small-mb__40 tablet-mb__40">
                                <h6 class="section-sub-title mb-20">Our company</h6>
                                <h3 class="heading">We’ve been working in <span class="text-color-primary">02 years</span></h3>
                                <p class="text mt-20">We are a Consummate Custom Software & Mobile Application Development company delivering splendid business IT Solutions and related services to customers across the globe. Our development services are led by our dedicated and passionate team to provide best industry practices combined with technology expertise and business domain knowledge to drive digital transformation. Our proficiency in understanding business challenges and professional competence allows us to create a better experience for our customers.</p>

                                <div class="sider-title-button-box mt-30">
                                    <a [routerLink]="['/contact']" class="ht-btn ht-btn-md text-white">Contact us</a>
                                </div>

                            </div>
                        </div>

                        <!-- <div class="col-lg-5 ml-auto">
                            <div class="ht-list style-auto-numbered-02">
                                <div class="list-item">
                                    <a class="link" href="#">
                                        <div class="list-header">
                                            <div class="marker">
                                                01
                                            </div>
                                            <div class="title-wrap">
                                                <h6 class="title">How we can help your business? </h6>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div class="list-item">
                                    <a class="link" href="#">
                                        <div class="list-header">
                                            <div class="marker">
                                                02
                                            </div>
                                            <div class="title-wrap">
                                                <h6 class="title">Why become our partner? </h6>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div class="list-item">
                                    <a class="link" href="#">
                                        <div class="list-header">
                                            <div class="marker"> 03</div>
                                            <div class="title-wrap">
                                                <h6 class="title"> What are the best of Mitech? </h6>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>

            <!--===========  feature-large-images-wrapper  Start =============-->
            <div class="feature-large-images-wrapper section-space--pt_100">
                <div class="container">

                    <div class="row">
                        <div class="col-lg-12">
                            <!-- section-title-wrap Start -->
                            <div class="section-title-wrap text-center section-space--mb_30">
                                <h6 class="section-sub-title mb-20">Hire us</h6>
                                <h3 class="heading">Why Work with <span class="text-color-primary"> Capset</span></h3>
                            </div>
                            <!-- section-title-wrap Start -->
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="row row--35">

                                <div class="col-lg-4 col-md-6  mt-30">
                                    <!-- Box large image warap Start -->
                                    <a [routerLink]="['/home']" class="box-large-image__wrap wow move-up">
                                        <div class="box-large-image__box">
                                            <div class="box-large-image__midea">
                                                <div class="images-midea">
                                                    <img src="capset_assets/images/box-image/blog-01-330x330.jpg" class="img-fluid" alt="" style="height: 344px;">

                                                    <!-- <div class="button-wrapper">
                                                        <div class="btn tm-button">
                                                            <span class="button-text">Expertise on Multiple Platforms</span>
                                                        </div>
                                                    </div> -->
                                                    <div class="heading-wrap">
                                                        <h5 class="heading">Expertise on Multiple Platforms</h5>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="box-large-image__content mt-30 text-center">
                                                <p>Capset has elite development team which has proficient expertise on the cutting-edge technologies and tools.</p>
                                            </div>
                                        </div>
                                    </a>
                                    <!-- Box large image warap End -->
                                </div>

                                <div class="col-lg-4 col-md-6 mt-30">
                                    <!-- Box large image warap Start -->
                                    <a [routerLink]="['/home']" class="box-large-image__wrap wow move-up">
                                        <div class="box-large-image__box">
                                            <div class="box-large-image__midea">
                                                <div class="images-midea">
                                                    <img src="capset_assets/images/box-image/home-processing-software-image-03.jpg" class="img-fluid" alt="">

                                                    <!-- <div class="button-wrapper">
                                                            <div class="btn tm-button">
                                                                <span class="button-text">Learn more</span>
                                                            </div>
                                                        </div> -->
                                                    <div class="heading-wrap">
                                                        <h5 class="heading">More than Decade of Experience</h5>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="box-large-image__content mt-30 text-center">
                                                <p>Our clients have always trusted us without any second thought because we have more than a decade of experience in delivering various successful projects.</p>
                                            </div>
                                        </div>
                                    </a>
                                    <!-- Box large image warap End -->
                                </div>

                                <div class="col-lg-4 col-md-6  mt-30">
                                    <!-- Box large image warap Start -->
                                    <a [routerLink]="['/home']" class="box-large-image__wrap wow move-up">
                                        <div class="box-large-image__box">
                                            <div class="box-large-image__midea">
                                                <div class="images-midea">
                                                    <img src="capset_assets/images/box-image/mitech-home-infotechno-box-large-image-03-330x330.jpg" class="img-fluid" alt="">

                                                    <div class="button-wrapper">
                                                        <div class="btn tm-button">
                                                            <span class="button-text">Learn more</span>
                                                        </div>
                                                    </div>
                                                    <div class="heading-wrap">
                                                        <h5 class="heading">Happy Clients base Across Globe</h5>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="box-large-image__content mt-40 text-center">
                                                <p>Capset is growing exponentially in its global outreach as well as quality work in all directions. </p>
                                            </div>
                                        </div>
                                    </a>
                                    <!-- Box large image warap End -->
                                </div>
                            </div>

                            <!-- <div class="section-under-heading text-center section-space--mt_40"><a href="#">Learn more about how we
                  work span <i class="ml-1 button-icon far fa-long-arrow-right"></i></a></div> -->

                        </div>
                    </div>
                </div>
            </div>
            <!--===========  feature-large-images-wrapper  End =============-->


        </div>
        <!-- ============ Our Experience Wrapper End =============== -->
        <!--=========== fun fact Wrapper Start ==========-->
        <div class="fun-fact-wrapper">
            <div class="container">
                <div class="fun-fact-grid-4">
                    <div class="grid-item  wow move-up">
                        <div class="fun-fact--one text-center">
                            <h5 class="fun-fact__title">Successfully work <br> with </h5>
                            <div class="fun-fact__count counter">170+</div>
                            <span class="fun-fact__text">HAPPY CLIENTS</span>
                        </div>
                    </div>
                    <div class="grid-item  wow move-up">
                        <div class="fun-fact--one text-center">
                            <h5 class="fun-fact__title">Successfully <br> completed</h5>
                            <div class="fun-fact__count counter">245+</div>
                            <span class="fun-fact__text">Finished projects</span>
                        </div>
                    </div>
                    <div class="grid-item  wow move-up">
                        <div class="fun-fact--one text-center">
                            <h5 class="fun-fact__title">Recruit more <br> than</h5>
                            <div class="fun-fact__count counter">35+</div>
                            <span class="fun-fact__text">SKilled Experts</span>
                        </div>
                    </div>
                    <div class="grid-item  wow move-up">
                        <div class="fun-fact--one text-center">
                            <h5 class="fun-fact__title">We <br>have </h5>
                            <div class="fun-fact__count counter">15+</div>
                            <span class="fun-fact__text">Teams</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--=========== fun fact Wrapper End ==========-->
        <!--=========== Infotechno Video Wrapper Start ==========-->
        <div class="infotechno-video-wrapper infotechno-video-ptb infotechno-video-bg">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 col-md-10 col-11 ml-auto">
                        <div class="video-content-wrap">
                            <div class="vieeo-content-inner">
                                <h2 class="heading">Let’s grow smarter with the smartest <br> Web Development Company.</h2>

                                <!-- <div class="viewo-text-wrap">
                  <div class="video-popup infotech-video-box">
                    <a href="https://www.youtube.com/watch?v=9No-FiEInLA" class="single-popup-wrap video-link">
                      <div class="video-content">
                        <div class="ht-popup-video video-button">
                          <div class="video-mark">
                            <div class="wave-pulse wave-pulse-1"></div>
                            <div class="wave-pulse wave-pulse-2"></div>
                          </div>
                          <div class="video-button__two">
                            <div class="video-play">
                              <span class="video-play-icon"></span>
                            </div>
                          </div>
                        </div>
                        <div class="video-text">
                          Watch video<br>intro
                        </div>
                      </div>
                    </a>
                  </div>

                </div> -->

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--=========== Infotechno Video Wrapper End ==========-->
        <!--===========  Projects wrapper Start =============-->
        <!-- <div class="projects-wrapper projectinfotechno-bg section-space--ptb_100">
            <div class="container">

                <div class="row">
                    <div class="col-lg-12">
                        <div class="section-title-wrap text-center section-space--mb_40">
                            <h6 class="section-sub-title mb-20">Case studies</h6>
                            <h3 class="heading">Proud projects that<span class="text-color-primary"> make us stand out</span></h3>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="projects-wrap swiper-container projects-slider__container">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide">
                                    <a href="#" class="projects-wrap style-01 wow move-up">
                                        <div class="projects-image-box">
                                            <div class="projects-image">
                                                <img class="img-fluid" src="capset_assets/images/projects/case-study-01-480x298.jpg" alt="">
                                            </div>
                                            <div class="content">
                                                <h6 class="heading">Mitech-Smart Vision</h6>
                                                <div class="post-categories">Cyber Security</div>
                                                <div class="text">At Mitech, we have a holistic and integrated approach towards core modernization to experience technological evolution.
                                                </div>
                                                <div class="box-projects-arrow">
                                                    <span class="button-text">View case study</span>
                                                    <i class="fa fa-long-arrow-right ml-1"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>

                                <div class="swiper-slide">
                                    <a href="#" class="projects-wrap style-01 wow move-up">
                                        <div class="projects-image-box">
                                            <div class="projects-image">
                                                <img class="img-fluid" src="capset_assets/images/projects/case-study-02-480x298.jpg" alt="">
                                            </div>
                                            <div class="content">
                                                <h6 class="heading">Arden-Internal Networking</h6>
                                                <div class="post-categories">Cyber Security</div>
                                                <div class="text">The prospects for a company operating in this new market are varied and exciting. The potential …
                                                </div>
                                                <div class="box-projects-arrow">
                                                    <span class="button-text">View case study</span>
                                                    <i class="fa fa-long-arrow-right ml-1"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>

                                <div class="swiper-slide">
                                    <a href="#" class="projects-wrap style-01 wow move-up">
                                        <div class="projects-image-box">
                                            <div class="projects-image">
                                                <img class="img-fluid" src="capset_assets/images/projects/case-study-03-480x298.jpg" alt="">
                                            </div>
                                            <div class="content">
                                                <h6 class="heading">A Freeserve case study</h6>
                                                <div class="post-categories">Cyber Security</div>
                                                <div class="text">The prospects for a company operating in this new market are varied and exciting. The potential …
                                                </div>
                                                <div class="box-projects-arrow">
                                                    <span class="button-text">View case study</span>
                                                    <i class="fa fa-long-arrow-right ml-1"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>

                                <div class="swiper-slide">
                                    <a href="#" class="projects-wrap style-01 wow move-up">
                                        <div class="projects-image-box">
                                            <div class="projects-image">
                                                <img class="img-fluid" src="capset_assets/images/projects/case-study-04-480x298.jpg" alt="">
                                            </div>
                                            <div class="content">
                                                <h6 class="heading">Aqua – Research and Energy</h6>
                                                <div class="post-categories">Cyber Security</div>
                                                <div class="text">The prospects for a company operating in this new market are varied and exciting. The potential …
                                                </div>
                                                <div class="box-projects-arrow">
                                                    <span class="button-text">View case study</span>
                                                    <i class="fa fa-long-arrow-right ml-1"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>

                            </div>
                            <div class="swiper-pagination swiper-pagination-project mt_20"></div>
                        </div>

                        <div class="section-under-heading text-center section-space--mt_40">Challenges are just opportunities in disguise. <a href="#">Take the challenge!</a></div>

                    </div>
                </div>
            </div>
        </div> -->
        <!--===========  Projects wrapper End =============-->

        <!--====================  testimonial section ====================-->
        <div class="testimonial-slider-area section-space--ptb_120 bg-gray-3">
            <div class="container-fluid container-fluid--cp-80">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="section-title-wrap text-center section-space--mb_60">
                            <h6 class="section-sub-title mb-20">Testimonials</h6>
                            <h3 class="heading">Why do people praise about<span class="text-color-primary"> @Capset?</span></h3>
                        </div>
                        <div class="testimonial-slider">
                            <div class="swiper-container testimonial-slider__container-two">
                                <div class="swiper-wrapper testimonial-slider__wrapper">
                                    <div class="swiper-slide">
                                        <div class="testimonial-slider__single wow move-up">
                                            <h6 class="testimonial-subject">Capset Infotech did a wonderful job with our financial modelling project.
                                            </h6>
                                            <div class="testimonial-slider__text">They understood all the subtle variences of the project and helped push it over the finish line. </div>
                                            <div class="author-info">
                                                <div class="testimonial-slider__media">
                                                    <img src="capset_assets/images/testimonial/mitech-testimonial-avata-04-70x70.jpg" class="img-fluid" alt="">
                                                </div>
                                                <div class="testimonial-slider__author">
                                                    <h6 class="name">Maggie Strickland</h6>
                                                    <!-- <span class="designation">WEB DESIGNER</span> -->
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="swiper-slide">
                                        <div class="testimonial-slider__single wow move-up">
                                            <h6 class="testimonial-subject">Capset Infotech Digital was truly committed to this project. They worked high quality.
                                            </h6>
                                            <div class="testimonial-slider__text">They also offered feedback & suggestions on how we can improve aspects of the larger project.</div>
                                            <div class="author-info">
                                                <div class="testimonial-slider__media">
                                                    <img src="capset_assets/images/testimonial/mitech-testimonial-avata-02-70x70.jpg" class="img-fluid" alt="">
                                                </div>
                                                <div class="testimonial-slider__author">
                                                    <h6 class="name">Abbie Ferguson</h6>
                                                    <!-- <span class="designation">Marketing</span> -->
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="swiper-slide">
                                        <div class="testimonial-slider__single wow move-up">
                                            <h6 class="testimonial-subject">Capset took my vision and made it a reality. Excellent communication and very clean code.
                                            </h6>
                                            <div class="testimonial-slider__text">I highly recommend them to accomplish any development task big or small. Worth. Every.</div>
                                            <div class="author-info">
                                                <div class="testimonial-slider__media">
                                                    <img src="capset_assets/images/testimonial/mitech-testimonial-avata-01-70x70.jpg" class="img-fluid" alt="">
                                                </div>
                                                <div class="testimonial-slider__author">
                                                    <h6 class="name">Stephen Mearsley</h6>
                                                    <!-- <span class="designation">PR Officer</span> -->
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="swiper-slide">
                                        <div class="testimonial-slider__single wow move-up">
                                            <h6 class="testimonial-subject">The team at Capset Infotech Digital is an incredibly detailed and dedicated.
                                            </h6>
                                            <div class="testimonial-slider__text">They have a very good work ethic when coming across any project and are very good with communication. </div>
                                            <div class="author-info">
                                                <div class="testimonial-slider__media">
                                                    <img src="capset_assets/images/testimonial/mitech-testimonial-avata-03-70x70.jpg" class="img-fluid" alt="">
                                                </div>
                                                <div class="testimonial-slider__author">
                                                    <h6 class="name">Monica Blews</h6>
                                                    <!-- <span class="designation">PR Officer</span> -->
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-pagination swiper-pagination-t0 section-space--mt_40"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <br/> -->
        <!--====================  End of testimonial section  ====================-->


        <!--====================  Blog Section Start ====================-->

        <!--====================  Conact us Section End  ====================-->
    </div>
</div>