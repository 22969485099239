<div id="main-wrapper">
    <div class="site-wrapper-reveal">
        <div class="about-banner-wrap banner-space about-us-bg">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8 ml-auto mr-auto">
                        <div class="about-banner-content text-center">
                            <h1 class="mb-15 text-white">About Capset </h1>
                            <h5 class="font-weight--normal text-white">Capset Infotech specializes in IT-related services such as Web & Mobile App Developments, Custom Products Develpoments , Web Designing, Marketing, etc.</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--===========  feature-large-images-wrapper  Start =============-->
        <div class="feature-large-images-wrapper section-space--ptb_100">
            <div class="container">

                <div class="row">
                    <div class="col-lg-12">
                        <!-- section-title-wrap Start -->
                        <div class="section-title-wrap text-center section-space--mb_60">
                            <h6 class="section-sub-title mb-20">Our company</h6>
                            <h3 class="heading">We run all kinds of <span class="text-color-primary">IT services</span></h3>
                        </div>
                        <!-- section-title-wrap Start -->
                    </div>
                </div>

                <div class="cybersecurity-about-box section-space--pb_70">
                    <div class="row">
                        <div class="col-lg-4 offset-lg-1">
                            <div class="modern-number-01">
                                <h2 class="heading  mr-5"><span class="mark-text">02</span>Years’ Experience in IT</h2>
                                <h6 class="heading mt-30">More About Our Success Stories</h6>
                            </div>
                        </div>

                        <div class="col-lg-5 offset-lg-1">
                            <div class="cybersecurity-about-text">
                                <div class="text">We are a Consummate Custom Software & Mobile Application Development company delivering splendid business IT Solutions and related services to customers across the globe. Our development services are led by our dedicated and passionate team to provide best industry practices combined with technology expertise and business domain knowledge to drive digital transformation.</div>
                                <!-- <div class="button-text">
                                    <a href="#" class="btn-text">
                                        Discover now
                                        <span class="button-icon ml-1">
                                <i class="far fa-long-arrow-right"></i>
                            </span>
                                    </a>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>


                <div class="row">
                    <div class="col-12">
                        <div class="feature-images__six">
                            <div class="row">
                                <div class="col-lg-4 col-md-6 wow move-up">
                                    <!-- ht-box-icon Start -->
                                    <div class="ht-box-images style-06">
                                        <div class="image-box-wrap">
                                            <div class="box-image">
                                                <div class="default-image">
                                                    <img class="img-fulid" src="capset_assets/images/icons/mitech-box-image-style-06-image-01-120x120.png" alt="">
                                                </div>
                                            </div>
                                            <div class="content">
                                                <h5 class="heading">Expertise on Multiple Platforms</h5>
                                                <div class="text">Twinet has elite development team which has proficient expertise on the cutting-edge technologies and tools.
                                                </div>
                                                <!-- <a href="#" class="box-images-arrow">
                                                    <span class="button-text">Discover now</span>
                                                    <i class="far fa-long-arrow-right"></i>
                                                </a> -->
                                            </div>
                                        </div>
                                    </div>
                                    <!-- ht-box-icon End -->
                                </div>
                                <div class="col-lg-4 col-md-6 wow move-up">
                                    <!-- ht-box-icon Start -->
                                    <div class="ht-box-images style-06">
                                        <div class="image-box-wrap">
                                            <div class="box-image">
                                                <div class="default-image">
                                                    <img class="img-fulid" src="capset_assets/images/icons/mitech-box-image-style-06-image-02-120x120.png" alt="">
                                                </div>
                                            </div>
                                            <div class="content">
                                                <h5 class="heading">Longterm Business Relationship</h5>
                                                <div class="text">We strive to be your trusted long term partner and leave no stone unturned to achieve the trustworthy relationship where our client can rely on us as a trusted IT company.
                                                </div>
                                                <!-- <a href="#" class="box-images-arrow">
                                                    <span class="button-text">Discover now</span>
                                                    <i class="far fa-long-arrow-right"></i>
                                                </a> -->
                                            </div>
                                        </div>
                                    </div>
                                    <!-- ht-box-icon End -->
                                </div>
                                <div class="col-lg-4 col-md-6 wow move-up">
                                    <!-- ht-box-icon Start -->
                                    <div class="ht-box-images style-06">
                                        <div class="image-box-wrap">
                                            <div class="box-image">
                                                <div class="default-image">
                                                    <img class="img-fulid" src="capset_assets/images/icons/mitech-box-image-style-06-image-03-120x120.png" alt="">
                                                </div>
                                            </div>
                                            <div class="content">
                                                <h5 class="heading">Happy Clients base Across Globe</h5>
                                                <div class="text">Capset is growing exponentially in its global outreach as well as quality work in all directions.
                                                </div>
                                                <!-- <a href="#" class="box-images-arrow">
                                                    <span class="button-text">Discover now</span>
                                                    <i class="far fa-long-arrow-right"></i>
                                                </a> -->
                                            </div>
                                        </div>
                                    </div>
                                    <!-- ht-box-icon End -->
                                </div>

                            </div>
                        </div>

                        <!-- <div class="section-under-heading text-center section-space--mt_60">Challenges are just opportunities in disguise. <a href="#">Take the challenge!</a></div> -->

                    </div>
                </div>
            </div>
        </div>
        <!--===========  feature-large-images-wrapper  End =============-->




        <!--========= About Resources Wrapper Start ===========-->
        <div class="about-resources-wrapper">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6 order-2 order-lg-1">
                        <div class="resources-left-box">
                            <div class="resources-inner">
                                <h6 class="sub-title mb-20">Capset says</h6>
                                <h3 class="heading">The world is changing very fast and digitally. <span class="text-color-primary"> For that, your business should be ready to enrich your goals.</span></h3>
                                <div class="button mt-30">
                                    <a routerLink="/contact" class="ht-btn ht-btn-md">Contact us</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-lg-6 order-1 order-lg-2">
                        <div class="resources-right-box">
                            <div class="resources-right-inner text-center">
                                <div class="resources-images">
                                    <img class="img-fluid" src="capset_assets/images/banners/managed-it-services-book-cover.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <!--========= About Resources Wrapper End ===========-->
        <!--========= About Delivering Wrapper Start ===========-->
        <div class="about-delivering-wrapper section-space--ptb_100">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="section-title-wrap text-center section-space--mb_20">
                            <h3 class="heading">How We Work</h3>
                            <div class="title-dec-text">Capset Infotech is a reputed Web Development Company that follows proven methodologies in attaining client satisfaction with a guaranteed result.</div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6">
                        <div class="delivering-optimal-wrap">
                            <div class="list-item">
                                <div class="one-icon"></div>
                                <div class="title-wrap">
                                    <h5 class="title">Requirement Gathering </h5>
                                    <div class="desc">We dig deep to know the requirement of clients and vision and create their roadmap. </div>
                                </div>
                            </div>
                            <div class="list-item">
                                <div class="two-icon"></div>
                                <div class="title-wrap">
                                    <h5 class="title"> Planning </h5>
                                    <div class="desc">Find the problem and organise the information to intuitively prepare a simplified plan. </div>
                                </div>
                            </div>
                            <div class="list-item">
                                <div class="three-icon"></div>
                                <div class="title-wrap">
                                    <h5 class="title">Design </h5>
                                    <div class="desc">We work to bring emotions to logics by prioritizing the look and feel of app. </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="delivering-optimal-wrap">
                            <div class="list-item">
                                <div class="four-icon"></div>
                                <div class="title-wrap">
                                    <h5 class="title">Development</h5>
                                    <div class="desc">With a meticulous development, we leverage incredible features and functions with expert engineering. </div>
                                </div>
                            </div>
                            <div class="list-item">
                                <div class="five-icon"></div>
                                <div class="title-wrap">
                                    <h5 class="title">Testing </h5>
                                    <div class="desc">Seamlessly test the application to review the performance across a multiple platform.</div>
                                </div>
                            </div>
                            <div class="list-item">
                                <div class="six-icon"></div>
                                <div class="title-wrap">
                                    <h5 class="title">Maintenance </h5>
                                    <div class="desc">Fixing the bug and errors and making your app free from any compatible issues. </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--=========== fun fact Wrapper Start ==========-->
        <div class="fun-fact-wrapper bg-theme-default section-space--pb_30 section-space--pt_60">
            <div class="container">
                <div class="row">
                    <div class="col-md-3 col-sm-6 wow move-up">
                        <div class="fun-fact--two text-center">
                            <div class="fun-fact__count counter">170+</div>
                            <h6 class="fun-fact__text">Happy clients</h6>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-6 wow move-up">
                        <div class="fun-fact--two text-center">
                            <div class="fun-fact__count counter">245+</div>
                            <h6 class="fun-fact__text">Finished projects</h6>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-6 wow move-up">
                        <div class="fun-fact--two text-center">
                            <div class="fun-fact__count counter">35+</div>
                            <h6 class="fun-fact__text">Skilled Experts</h6>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-6 wow move-up">
                        <div class="fun-fact--two text-center">
                            <div class="fun-fact__count counter">15+</div>
                            <h6 class="fun-fact__text">Team</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--=========== fun fact Wrapper End ==========-->
        <!--====================  testimonial section ====================-->
        <div class="testimonial-slider-area section-space--pt_100">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="section-title-wrap text-center section-space--mb_40">
                            <h6 class="section-sub-title mb-20">Testimonials</h6>
                            <h3 class="heading">What do people praise about <span class="text-color-primary"> @Capset?</span></h3>
                        </div>
                        <div class="testimonial-slider">
                            <div class="swiper-container testimonial-slider__container">
                                <div class="swiper-wrapper testimonial-slider__wrapper">
                                    <div class="swiper-slide">
                                        <div class="testimonial-slider__one wow move-up">

                                            <div class="testimonial-slider--info">
                                                <div class="testimonial-slider__media">
                                                    <img src="capset_assets/images/testimonial/mitech-testimonial-avata-02-90x90.jpg" class="img-fluid" alt="">
                                                </div>

                                                <div class="testimonial-slider__author">
                                                    <div class="testimonial-rating">
                                                        <span class="fa fa-star"></span>
                                                        <span class="fa fa-star"></span>
                                                        <span class="fa fa-star"></span>
                                                        <span class="fa fa-star"></span>
                                                        <span class="fa fa-star"></span>
                                                    </div>
                                                    <div class="author-info">
                                                        <h6 class="name">Abbie Ferguson</h6>
                                                        <!-- <span class="designation">Marketing</span> -->
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="testimonial-slider__text">Capset Infotech did a wonderful job with our financial modelling project. They understood all the subtle variences of the project and helped push it over the finish line.</div>

                                        </div>
                                    </div>
                                    <div class="swiper-slide">
                                        <div class="testimonial-slider__one wow move-up">

                                            <div class="testimonial-slider--info">
                                                <div class="testimonial-slider__media">
                                                    <img src="capset_assets/images/testimonial/mitech-testimonial-avata-03-90x90.jpg" class="img-fluid" alt="">
                                                </div>

                                                <div class="testimonial-slider__author">
                                                    <div class="testimonial-rating">
                                                        <span class="fa fa-star"></span>
                                                        <span class="fa fa-star"></span>
                                                        <span class="fa fa-star"></span>
                                                        <span class="fa fa-star"></span>
                                                        <span class="fa fa-star"></span>
                                                    </div>
                                                    <div class="author-info">
                                                        <h6 class="name">Monica Blews</h6>
                                                        <!-- <span class="designation">Web designer</span> -->
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="testimonial-slider__text">The team at Capset Infotech Digital is an incredibly detailed and dedicated. They have a very good work ethic when coming across any project and are very good with communication.</div>

                                        </div>
                                    </div>
                                    <div class="swiper-slide">
                                        <div class="testimonial-slider__one wow move-up">

                                            <div class="testimonial-slider--info">
                                                <div class="testimonial-slider__media">
                                                    <img src="capset_assets/images/testimonial/mitech-testimonial-avata-04-90x90.jpg" class="img-fluid" alt="">
                                                </div>

                                                <div class="testimonial-slider__author">
                                                    <div class="testimonial-rating">
                                                        <span class="fa fa-star"></span>
                                                        <span class="fa fa-star"></span>
                                                        <span class="fa fa-star"></span>
                                                        <span class="fa fa-star"></span>
                                                        <span class="fa fa-star"></span>
                                                    </div>
                                                    <div class="author-info">
                                                        <h6 class="name">Amit Maheta</h6>
                                                        <!-- <span class="designation">WEB DESIGNER</span> -->
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="testimonial-slider__text">Capset Infotech Digital was truly committed to this project. They worked quickly while maintaining high quality. They also offered feedback & suggestions on how we can improve aspects of the larger project,
                                                which was greatly appreciated.</div>

                                        </div>
                                    </div>
                                    <div class="swiper-slide">
                                        <div class="testimonial-slider__one wow move-up">

                                            <div class="testimonial-slider--info">
                                                <div class="testimonial-slider__media">
                                                    <img src="capset_assets/images/testimonial/mitech-testimonial-avata-01-90x90.jpg" class="img-fluid" alt="">
                                                </div>

                                                <div class="testimonial-slider__author">
                                                    <div class="testimonial-rating">
                                                        <span class="fa fa-star"></span>
                                                        <span class="fa fa-star"></span>
                                                        <span class="fa fa-star"></span>
                                                        <span class="fa fa-star"></span>
                                                        <span class="fa fa-star"></span>
                                                    </div>
                                                    <div class="author-info">
                                                        <h6 class="name">Abbie Ferguson</h6>
                                                        <!-- <span class="designation">WEB DESIGNER</span> -->
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="testimonial-slider__text">Capset took my vision and made it a reality. Excellent communication, thoughtful suggestions and very clean code. I highly recommend them to accomplish any development task big or small. Worth. Every. Dime.</div>

                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-pagination swiper-pagination-t01 section-space--mt_30"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--====================  End of testimonial section  ====================-->

        <!--====================  brand logo slider area ====================-->
        <div class="brand-logo-slider-area section-space--ptb_60">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <!-- brand logo slider -->
                        <!-- <div class="brand-logo-slider__container-area">
                            <div class="swiper-container brand-logo-slider__container">
                                <div class="swiper-wrapper brand-logo-slider__one">
                                    <div class="swiper-slide brand-logo brand-logo--slider">
                                        <a href="#">
                                            <div class="brand-logo__image">
                                                <img src="capset_assets/images/brand/mitech-client-logo-01.png" class="img-fluid" alt="">
                                            </div>
                                            <div class="brand-logo__image-hover">
                                                <img src="capset_assets/images/brand/mitech-client-logo-01-hover.png" class="img-fluid" alt="">
                                            </div>
                                        </a>
                                    </div>
                                    <div class="swiper-slide brand-logo brand-logo--slider">
                                        <a href="#">
                                            <div class="brand-logo__image">
                                                <img src="capset_assets/images/brand/mitech-client-logo-02.png" class="img-fluid" alt="">
                                            </div>
                                            <div class="brand-logo__image-hover">
                                                <img src="capset_assets/images/brand/mitech-client-logo-02-hover.png" class="img-fluid" alt="">
                                            </div>
                                        </a>
                                    </div>
                                    <div class="swiper-slide brand-logo brand-logo--slider">
                                        <a href="#">
                                            <div class="brand-logo__image">
                                                <img src="capset_assets/images/brand/mitech-client-logo-03.png" class="img-fluid" alt="">
                                            </div>
                                            <div class="brand-logo__image-hover">
                                                <img src="capset_assets/images/brand/mitech-client-logo-03-hover.png" class="img-fluid" alt="">
                                            </div>
                                        </a>
                                    </div>
                                    <div class="swiper-slide brand-logo brand-logo--slider">
                                        <a href="#">
                                            <div class="brand-logo__image">
                                                <img src="capset_assets/images/brand/mitech-client-logo-04.png" class="img-fluid" alt="">
                                            </div>
                                            <div class="brand-logo__image-hover">
                                                <img src="capset_assets/images/brand/mitech-client-logo-04-hover.png" class="img-fluid" alt="">
                                            </div>
                                        </a>
                                    </div>
                                    <div class="swiper-slide brand-logo brand-logo--slider">
                                        <a href="#">
                                            <div class="brand-logo__image">
                                                <img src="capset_assets/images/brand/mitech-client-logo-05.png" class="img-fluid" alt="">
                                            </div>
                                            <div class="brand-logo__image-hover">
                                                <img src="capset_assets/images/brand/mitech-client-logo-05-hover.png" class="img-fluid" alt="">
                                            </div>
                                        </a>
                                    </div>
                                    <div class="swiper-slide brand-logo brand-logo--slider">
                                        <a href="#">
                                            <div class="brand-logo__image">
                                                <img src="capset_assets/images/brand/mitech-client-logo-06.png" class="img-fluid" alt="">
                                            </div>
                                            <div class="brand-logo__image-hover">
                                                <img src="capset_assets/images/brand/mitech-client-logo-06-hover.png" class="img-fluid" alt="">
                                            </div>
                                        </a>
                                    </div>
                                    <div class="swiper-slide brand-logo brand-logo--slider">
                                        <a href="#">
                                            <div class="brand-logo__image">
                                                <img src="capset_assets/images/brand/mitech-client-logo-07.png" class="img-fluid" alt="">
                                            </div>
                                            <div class="brand-logo__image-hover">
                                                <img src="capset_assets/images/brand/mitech-client-logo-07-hover.png" class="img-fluid" alt="">
                                            </div>
                                        </a>
                                    </div>
                                    <div class="swiper-slide brand-logo brand-logo--slider">
                                        <a href="#">
                                            <div class="brand-logo__image">
                                                <img src="capset_assets/images/brand/mitech-client-logo-08.png" class="img-fluid" alt="">
                                            </div>
                                            <div class="brand-logo__image-hover">
                                                <img src="capset_assets/images/brand/mitech-client-logo-08-hover.png" class="img-fluid" alt="">
                                            </div>
                                        </a>
                                    </div>
                                    <div class="swiper-slide brand-logo brand-logo--slider">
                                        <a href="#">
                                            <div class="brand-logo__image">
                                                <img src="capset_assets/images/brand/mitech-client-logo-09.png" class="img-fluid" alt="">
                                            </div>
                                            <div class="brand-logo__image-hover">
                                                <img src="capset_assets/images/brand/mitech-client-logo-09-hover.png" class="img-fluid" alt="">
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
        <!--====================  End of brand logo slider area  ====================-->
        <!--============ Contact Us Area Start =================-->
        <div class="contact-us-area infotechno-contact-us-bg section-space--pt_100">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-7">
                        <div class="image">
                            <img class="img-fluid" src="capset_assets/images/banners/home-cybersecurity-contact-bg-image.png" alt="">
                        </div>
                    </div>

                    <div class="col-lg-4 ml-auto">
                        <div class="contact-info style-two text-left">

                            <div class="contact-info-title-wrap text-center">
                                <h3 class="heading  mb-10">4.9/5.0</h3>
                                <div class="ht-star-rating lg-style">
                                    <span class="fa fa-star"></span>
                                    <span class="fa fa-star"></span>
                                    <span class="fa fa-star"></span>
                                    <span class="fa fa-star"></span>
                                    <span class="fa fa-star"></span>
                                </div>
                                <p class="sub-text">by 120+ clients</p>
                            </div>

                            <div class="contact-list-item">
                                <a href="tel:9099310181" class="single-contact-list">
                                    <div class="content-wrap">
                                        <div class="content">
                                            <div class="icon">
                                                <span class="fal fa-phone"></span>
                                            </div>
                                            <div class="main-content">
                                                <h6 class="heading">Call for Enquiry now!</h6>
                                                <div class="text font-larger">+91(909)931-0181</div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                                <a href="mailto:capsetinfotech@gmail.com" class="single-contact-list">
                                    <div class="content-wrap">
                                        <div class="content">
                                            <div class="icon">
                                                <span class="fal fa-envelope"></span>
                                            </div>
                                            <div class="main-content">
                                                <h6 class="heading">Say hello</h6>
                                                <div class="text font-larger">capsetinfotech@gmail.com</div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--============ Contact Us Area End =================-->

    </div>

</div>