<div class="breadcrumb-area">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="breadcrumb_box text-center">
                    <h2 class="breadcrumb-title">Services</h2>
                    <!-- breadcrumb-list start -->
                    <ul class="breadcrumb-list">
                        <li class="breadcrumb-item"><a routerLink="/home" routerLinkActive="active">Home</a></li>
                        <li class="breadcrumb-item active">Services </li>
                    </ul>
                    <!-- breadcrumb-list end -->
                </div>
            </div>
        </div>
    </div>
</div>

<div id="main-wrapper">
    <div class="site-wrapper-reveal">
        <!--===========  feature-images-wrapper  Start =============-->
        <div class="feature-images-wrapper bg-gray section-space--ptb_100">
            <div class="container">

                <div class="row">
                    <div class="col-lg-12">
                        <!-- section-title-wrap Start -->
                        <div class="section-title-wrap text-center">
                            <h6 class="section-sub-title mb-20">Our services</h6>
                            <h3 class="heading">For your very specific industry, <br> we have <span class="text-color-primary">
                    highly-tailored IT solutions.</span></h3>
                        </div>
                        <!-- section-title-wrap Start -->
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="feature-images__one">
                            <div class="row">

                                <div class="col-lg-4 col-md-6 wow move-up">
                                    <!-- ht-box-icon Start -->
                                    <div class="ht-box-images style-01">
                                        <div class="image-box-wrap">
                                            <div class="box-image">
                                                <img class="img-fulid" src="capset_assets/images/icons/mitech-box-image-style-01-image-01-100x108.png" alt="">
                                            </div>
                                            <div class="content">
                                                <h5 class="heading">Web Developement</h5>
                                                <div class="text"> We bring revolution to reality. Transforming your custom business ideas into an innovative next-gen web app is our forte. With proven results and promising qualitative deliverables across multiple tech markets.
                                                </div>
                                                <!-- <div class="circle-arrow">
                                                    <div class="middle-dot"></div>
                                                    <div class="middle-dot dot-2"></div>
                                                    <a [routerLink]="['/services']">
                                                        <i class="far fa-long-arrow-right"></i>
                                                    </a>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                    <!-- ht-box-icon End -->
                                </div>

                                <div class="col-lg-4 col-md-6 wow move-up">
                                    <!-- ht-box-icon Start -->
                                    <div class="ht-box-images style-01">
                                        <div class="image-box-wrap">
                                            <div class="box-image">
                                                <img class="img-fulid" src="capset_assets/images/icons/mitech-box-image-style-01-image-02-100x108.png" alt="">
                                            </div>
                                            <div class="content">
                                                <h5 class="heading">Mobile App Development</h5>
                                                <div class="text">The exponential increase in mobile users globally has made consumption of data quite extensive. It has also opened an opportunity for entrepreneurs to think and implement innovative business ideas.
                                                </div>
                                                <!-- <div class="circle-arrow">
                                                    <div class="middle-dot"></div>
                                                    <div class="middle-dot dot-2"></div>
                                                    <a routerLink="/services">
                                                        <i class="far fa-long-arrow-right"></i>
                                                    </a>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                    <!-- ht-box-icon End -->
                                </div>

                                <div class="col-lg-4 col-md-6 wow move-up">
                                    <!-- ht-box-icon Start -->
                                    <div class="ht-box-images style-01">
                                        <div class="image-box-wrap">
                                            <div class="box-image">
                                                <img class="img-fulid" src="capset_assets/images/icons/mitech-box-image-style-01-image-03-100x108.png" alt="">
                                            </div>
                                            <div class="content">
                                                <h5 class="heading">Software Development</h5>
                                                <div class="text">The custom software development process encompasses designing, developing, deploying, and maintaining custom software solutions and services. As a leading custom software development company.
                                                </div>
                                                <!-- <div class="circle-arrow">
                                                    <div class="middle-dot"></div>
                                                    <div class="middle-dot dot-2"></div>
                                                    <a routerLink="/services">
                                                        <i class="far fa-long-arrow-right"></i>
                                                    </a>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                    <!-- ht-box-icon End -->
                                </div>

                                <div class="col-lg-4 col-md-6 wow move-up">
                                    <!-- ht-box-icon Start -->
                                    <div class="ht-box-images style-01">
                                        <div class="image-box-wrap">
                                            <div class="box-image">
                                                <img class="img-fulid" src="capset_assets/images/icons/mitech-box-image-style-01-image-03-100x108.png" alt="">
                                            </div>
                                            <div class="content">
                                                <h5 class="heading">Digital Marketing</h5>
                                                <div class="text">We’re A Supportive Community Leading Change In Digital Project Management. We understand the importance of formulating the strategies and interpret the analytics followed by running an on-point ads campaign.
                                                </div>
                                                <!-- <div class="circle-arrow">
                                                    <div class="middle-dot"></div>
                                                    <div class="middle-dot dot-2"></div>
                                                    <a routerLink="/services">
                                                        <i class="far fa-long-arrow-right"></i>
                                                    </a>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                    <!-- ht-box-icon End -->
                                </div>

                                <div class="col-lg-4 col-md-6 wow move-up">
                                    <!-- ht-box-icon Start -->
                                    <div class="ht-box-images style-01">
                                        <div class="image-box-wrap">
                                            <div class="box-image">
                                                <img class="img-fulid" src="capset_assets/images/icons/mitech-box-image-style-01-image-03-100x108.png" alt="">
                                            </div>
                                            <div class="content">
                                                <h5 class="heading">UI/UX Design</h5>
                                                <div class="text">Explore innovative digital experience by offering a blend of technology, creativity and personalization in UI and UX development services. We design captivating user experience with a significant.
                                                </div>
                                                <!-- <div class="circle-arrow">
                                                    <div class="middle-dot"></div>
                                                    <div class="middle-dot dot-2"></div>
                                                    <a routerLink="/services">
                                                        <i class="far fa-long-arrow-right"></i>
                                                    </a>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                    <!-- ht-box-icon End -->
                                </div>

                                <div class="col-lg-4 col-md-6 wow move-up">
                                    <!-- ht-box-icon Start -->
                                    <div class="ht-box-images style-01">
                                        <div class="image-box-wrap">
                                            <div class="box-image">
                                                <img class="img-fulid" src="capset_assets/images/icons/mitech-box-image-style-01-image-03-100x108.png" alt="">
                                            </div>
                                            <div class="content">
                                                <h5 class="heading">Capset Digital Project</h5>
                                                <div class="text">To convert your vision into reality, we assist you from software product ideation to design, development, release, and maintenance. Every successful product is unique in its market and with its uniqueness.
                                                </div>
                                                <!-- <div class="circle-arrow">
                                                    <div class="middle-dot"></div>
                                                    <div class="middle-dot dot-2"></div>
                                                    <a routerLink="/services">
                                                        <i class="far fa-long-arrow-right"></i>
                                                    </a>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                    <!-- ht-box-icon End -->
                                </div>

                            </div>
                        </div>

                        <!-- <div class="section-under-heading text-center section-space--mt_60 section-space--pt_30">Challenges are just opportunities in disguise. <a href="#">Take the challenge!</a></div> -->

                    </div>
                </div>
            </div>
        </div>
        <!--===========  feature-images-wrapper  End =============-->
    </div>

    <!--========== Call to Action Area Start ============-->
    <div class="cta-image-area_one section-space--ptb_80 cta-bg-image_one">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-xl-8 col-lg-7">
                    <div class="cta-content md-text-center">
                        <h3 class="heading text-white">We run all kinds of <span class="text-color-secondary"> IT services</span></h3>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-5">
                    <div class="cta-button-group--one text-center">
                        <a href="https://api.whatsapp.com/send?phone=919099310181&text=Hello Capset Infotech, I'm interested regarding IT services" target="_blank" class="btn btn--white btn-one"><span class="btn-icon mr-2"><i
                class="far fa-comment-alt-dots"></i></span> Let's talk</a>
                        <a [routerLink]="['/contact']" class="btn btn--secondary  btn-two"><span class="btn-icon mr-2"><i
                class="far fa-info-circle"></i></span> Get info</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--========== Call to Action Area End ============-->
</div>