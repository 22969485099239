<div class="preloader-activate preloader-active open_tm_preloader">
    <div class="preloader-area-wrap">
        <div class="spinner d-flex justify-content-center align-items-center h-100">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
        </div>
    </div>
</div>



<!--====================  header area ====================-->
<div class="header-area header-area--default">

    <!-- Header Top Wrap Start -->
    <div class="header-top-bar-info bg-gray d-none d-lg-block">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="top-bar-wrap">
                        <div class="top-bar-left">
                            <div class="top-bar-text"><a href="https://api.whatsapp.com/send?phone=919099310181&text=Hello Capset Infotech, I'm interested for job" target="_blank" class="font-medium display-inline">Now Hiring:</a> We are hiring Android developers.</div>
                        </div>
                        <div class="top-bar-right">
                            <ul class="top-bar-info">
                                <li class="info-item">
                                    <a href="tel:9099310181" class="info-link">
                                        <i class="info-icon fa fa-phone" style="transform: rotate(90deg);"></i>
                                        <span class="info-text"><strong>+91(909)931-0181</strong></span>
                                    </a>
                                </li>
                                <li class="info-item">
                                    <i class="info-icon fa fa-map-marker-alt"></i>
                                    <span class="info-text">905, Orbit-1 Business center, Punagam-Saroli Road, Punagam, Surat, Gujarat, India</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Header Top Wrap End -->

    <!-- Header Bottom Wrap Start -->
    <div class="header-bottom-wrap header-sticky">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="header default-menu-style position-relative">

                        <!-- brand logo -->
                        <div class="header__logo">
                            <a [routerLink]="['/home']">
                                <img src="capset_assets/images/logo/capset.png" class="img-fluid" alt="" style="width: 180px;">
                            </a>
                        </div>

                        <!-- header midle box  -->
                        <div class="header-midle-box">
                            <div class="header-bottom-wrap d-md-block d-none">
                                <div class="header-bottom-inner">
                                    <div class="header-bottom-left-wrap">
                                        <!-- navigation menu -->
                                        <div class="header__navigation d-none d-xl-block">
                                            <nav class="navigation-menu primary--menu">
                                                <ul>
                                                    <li><a [routerLink]="['/home']" routerLinkActive="active"><span>Home</span></a></li>
                                                    <li><a [routerLink]="['/about']" routerLinkActive="active"><span>About</span></a></li>
                                                    <li><a [routerLink]="['/services']" routerLinkActive="active"><span>Services</span></a></li>
                                                    <li><a [routerLink]="['/technology']" routerLinkActive="active"><span>Technology</span></a></li>
                                                    <li><a [routerLink]="['/contact']"><span>Contact</span></a></li>
                                                    <!-- <li class="has-children has-children--multilevel-submenu">
                                                        <a href="#"><span>Services</span></a>
                                                        <ul class="submenu">
                                                            <li><a [routerLink]="['/']"><span>Web Development</span></a></li>
                                                            <li><a [routerLink]="['/']"><span>App Development</span></a></li>
                                                            <li><a [routerLink]="['/']"><span>Graphic Design</span></a></li>
                                                            <li><a [routerLink]="['/']"><span>Digital Marketing</span></a></li>
                                                        </ul>
                                                    </li> -->
                                                    <!-- <li class="has-children has-children--multilevel-submenu">
                                                        <a href="#"><span>Company</span></a>
                                                        <ul class="submenu">
                                                            <li class="has-children">
                                                                <a href="about-us-01.html"><span>About us</span></a>
                                                                <ul class="submenu">
                                                                    <li><a href="about-us-01.html"><span>About us 01</span></a></li>
                                                                    <li><a href="about-us-02.html"><span>About us 02</span></a></li>
                                                                    <li class="has-children">
                                                                        <a href="#"><span>Submenu Level Two</span></a>
                                                                        <ul class="submenu">
                                                                            <li><a href="#"><span>Submenu Level Three</span></a></li>
                                                                            <li><a href="#"><span>Submenu Level Three</span></a></li>
                                                                            <li><a href="#"><span>Submenu Level Three</span></a></li>
                                                                        </ul>
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                            <li><a href="contact-us.html"><span>Contact us</span></a></li>
                                                            <li><a href="leadership.html"><span>Leadership</span></a></li>
                                                            <li><a href="why-choose-us.html"><span>Why choose us</span></a></li>
                                                            <li><a href="our-history.html"><span>Our history</span></a></li>
                                                            <li><a href="faqs.html"><span>FAQs</span></a></li>
                                                            <li><a href="careers.html"><span>Careers</span></a></li>
                                                            <li><a href="pricing-plans.html"><span>Pricing plans</span></a></li>
                                                        </ul>
                                                    </li> -->
                                                    <!-- <li class="has-children has-children--multilevel-submenu">
                                                        <a href="#"><span>IT solutions</span></a>
                                                        <ul class="submenu">
                                                            <li><a href="it-services.html"><span>IT Services</span></a></li>
                                                            <li><a href="managed-it-service.html"><span>Managed IT Services</span></a></li>
                                                            <li><a href="industries.html"><span>Industries</span></a></li>
                                                            <li><a href="business-solution.html"><span>Business solution</span></a></li>
                                                            <li><a href="it-services-details.html"><span>IT Services Details</span></a></li>
                                                        </ul>
                                                    </li> -->
                                                    <!-- <li class="has-children">
                                                        <a href="#"><span>Elements</span></a>
                                                        <ul class="megamenu megamenu--mega">
                                                            <li>
                                                                <h2 class="page-list-title">ELEMENT GROUP 01</h2>
                                                                <ul>
                                                                    <li><a href="element-accordion.html"><span>Accordions & Toggles</span></a></li>
                                                                    <li><a href="element-box-icon.html"><span>Box Icon</span></a></li>
                                                                    <li><a href="element-box-image.html"><span>Box Image</span></a></li>
                                                                    <li><a href="element-box-large-image.html"><span>Box Large Image</span></a></li>
                                                                    <li><a href="element-buttons.html"><span>Buttons</span></a></li>
                                                                    <li><a href="element-cta.html"><span>Call to action</span></a></li>
                                                                    <li><a href="element-client-logo.html"><span>Client Logo</span></a></li>
                                                                    <li><a href="element-countdown.html"><span>Countdown</span></a></li>
                                                                </ul>
                                                            </li>
                                                            <li>
                                                                <h2 class="page-list-title">ELEMENT GROUP 02</h2>
                                                                <ul>
                                                                    <li><a href="element-counters.html"><span>Counters</span></a></li>
                                                                    <li><a href="element-dividers.html"><span>Dividers</span></a></li>
                                                                    <li><a href="element-flexible-image-slider.html"><span>Flexible image slider</span></a></li>
                                                                    <li><a href="element-google-map.html"><span>Google Map</span></a></li>
                                                                    <li><a href="element-gradation.html"><span>Gradation</span></a></li>
                                                                    <li><a href="element-instagram.html"><span>Instagram</span></a></li>
                                                                    <li><a href="element-lists.html"><span>Lists</span></a></li>
                                                                    <li><a href="element-message-box.html"><span>Message box</span></a></li>
                                                                </ul>
                                                            </li>
                                                            <li>
                                                                <h2 class="page-list-title">ELEMENT GROUP 03</h2>
                                                                <ul>
                                                                    <li><a href="element-popup-video.html"><span>Popup Video</span></a></li>
                                                                    <li><a href="element-pricing-box.html"><span>Pricing Box</span></a></li>
                                                                    <li><a href="element-progress-bar.html"><span>Progress Bar</span></a></li>
                                                                    <li><a href="element-progress-circle.html"><span>Progress Circle</span></a></li>
                                                                    <li><a href="element-rows-columns.html"><span>Rows & Columns</span></a></li>
                                                                    <li><a href="element-social-networks.html"><span>Social Networks</span></a></li>
                                                                    <li><a href="element-tabs.html"><span>Tabs</span></a></li>
                                                                    <li><a href="element-team-member.html"><span>Team member</span></a></li>
                                                                </ul>
                                                            </li>
                                                            <li>
                                                                <h2 class="page-list-title">ELEMENT GROUP 04</h2>
                                                                <ul>
                                                                    <li><a href="element-testimonials.html"><span>Testimonials</span></a></li>
                                                                    <li><a href="element-timeline.html"><span>Timeline</span></a></li>
                                                                    <li><a href="element-carousel-sliders.html"><span>Carousel Sliders</span></a></li>
                                                                    <li><a href="element-typed-text.html"><span>Typed Text</span></a></li>
                                                                </ul>
                                                            </li>
                                                        </ul>
                                                    </li> -->
                                                    <!-- <li class="has-children has-children--multilevel-submenu">
                                                        <a href="#"><span>Case Studies</span></a>
                                                        <ul class="submenu">
                                                            <li><a href="case-studies.html"><span>Case Studies 01</span></a></li>
                                                            <li><a href="case-studies-02.html"><span>Case Studies 02</span></a></li>
                                                            <li><a href="single-smart-vision.html"><span>Single Layout</span></a></li>
                                                        </ul>
                                                    </li> -->
                                                    <!-- <li class="has-children has-children--multilevel-submenu">
                                                        <a href="blog-list-large-image.html"><span>Blog</span></a>
                                                        <ul class="submenu">
                                                            <li><a href="blog-list-large-image.html"><span>List Large Image</span></a></li>
                                                            <li><a href="blog-list-left-large-image.html"><span>Left Large Image</span></a></li>
                                                            <li><a href="blog-grid-classic.html"><span>Grid Classic</span></a></li>
                                                            <li><a href="blog-grid-masonry.html"><span>Grid Masonry</span></a></li>
                                                            <li class="has-children">
                                                                <a href="blog-post-layout-one.html"><span>Single Layouts</span></a>
                                                                <ul class="submenu">
                                                                    <li><a href="blog-post-layout-one.html"><span>Left Sidebar</span></a></li>
                                                                    <li><a href="blog-post-right-sidebar.html"><span>Right Sidebar</span></a></li>
                                                                    <li><a href="blog-post-no-sidebar.html"><span>No Sidebar</span></a></li>
                                                                </ul>
                                                            </li>
                                                        </ul>
                                                    </li> -->
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <!-- header right box -->
                        <div class="header-right-box">
                            <div class="header-right-inner" id="hidden-icon-wrapper">

                                <!-- language-menu -->
                                <!-- <div class="language-menu">
                                    <ul>
                                        <li>
                                            <a href="#" class="">
                                                <img class="ls-flag" src="capset_assets/images/flags/en.png" alt="en" title="English">
                                                <span class="wpml-ls-native">English</span>
                                            </a>

                                            <ul class="ls-sub-menu">
                                                <li class="">
                                                    <a href="#" class="">
                                                        <img class="ls-flag" src="capset_assets/images/flags/fr.png" alt="en" title="Français"><span class="wpml-ls-native">Français</span>
                                                    </a>
                                                </li>
                                                <li class="">
                                                    <a href="#" class="">
                                                        <img class="wpml-ls-flag" src="capset_assets/images/flags/de.png" alt="de" title="Deutsch"><span class="wpml-ls-native">Deutsch</span>
                                                    </a>
                                                </li>

                                            </ul>

                                        </li>

                                    </ul>
                                </div> -->

                                <!-- header-search-form -->
                                <!-- <div class="header-search-form default-search">
                                    <form action="#" class="search-form-top">
                                        <input class="search-field" type="text" placeholder="Search…">
                                        <button class="search-submit">
                      <i class="search-btn-icon fa fa-search"></i>
                    </button>
                                    </form>
                                </div> -->
                            </div>

                            <!-- mobile menu -->
                            <div class="mobile-navigation-icon d-block d-xl-none" id="mobile-menu-trigger">
                                <i></i>
                            </div>
                            <!-- hidden icons menu -->
                            <!-- <div class="hidden-icons-menu d-block d-md-none" id="hidden-icon-trigger">
                                <a href="javascript:void(0)">
                                    <i class="far fa-ellipsis-h-alt"></i>
                                </a>
                            </div> -->
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Header Bottom Wrap End -->

</div>
<!--====================  End of header area  ====================-->


<router-outlet>
</router-outlet>

<div id="main-wrapper">
    <!--====================  footer area ====================-->
    <div class="footer-area-wrapper bg-gray">
        <div class="footer-area section-space--ptb_80">
            <div class="container">
                <div class="row footer-widget-wrapper">
                    <div class="col-lg-4 col-md-6 col-sm-6 footer-widget">
                        <div class="footer-widget__logo mb-30">
                            <!-- capset_assets/images/logo/dark-logo-160x48.png -->
                            <img src="capset_assets/images/logo/capset.png" class="img-fluid" alt="Capset Infotech" style="width: 180px;">
                        </div>
                        <ul class="footer-widget__list">
                            <li class="capitlize">905, Orbit-1 Business center, Punagam-Saroli Road, near Canal Corridor, Punagam, Surat, Gujarat, India - 395010</li>
                            <li><a href="mailto:capsetinfotech@gmail.com" class="hover-style-link">capsetinfotech@gmail.com</a></li>
                            <li><a href="tel:9099310181" class="hover-style-link text-black font-weight--bold">+91(909)931-0181</a></li>
                            <!-- <li><a href="tel:9099310181" class="hover-style-link text-black font-weight--bold">+91(951)206-1005</a></li> -->
                        </ul>
                    </div>
                    <div class="col-lg-2 col-md-4 col-sm-6 footer-widget">
                        <h6 class="footer-widget__title mb-20">Quick links</h6>
                        <ul class="footer-widget__list">
                            <li><a [routerLink]="['/home']" routerLinkActive="active" class="hover-style-link">Home</a></li>
                            <li><a [routerLink]="['/about']" routerLinkActive="active" class="hover-style-link">About</a></li>
                            <li><a [routerLink]="['/services']" routerLinkActive="active" class="hover-style-link">Services</a></li>
                            <li><a [routerLink]="['/technology']" routerLinkActive="active" class="hover-style-link">Technology</a></li>
                            <li><a [routerLink]="['/contact']" routerLinkActive="active" class="hover-style-link">Contact</a></li>
                        </ul>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12 footer-widget">
                        <h6 class="footer-widget__title mb-20">Reach Us</h6>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14877.872180251283!2d72.83496347963018!3d21.21328087437772!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04f15d3389b77%3A0xe030d20da82a4bd0!2sTwinnet%20Technologies!5e0!3m2!1sen!2sin!4v1612677370298!5m2!1sen!2sin"
                            width="100%" height="230" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                    </div>
                    <!-- <div class="col-lg-2 col-md-4 col-sm-6 footer-widget">
                        <h6 class="footer-widget__title mb-20">Quick links</h6>
                        <ul class="footer-widget__list">
                            <li><a href="#" class="hover-style-link">Pick up locations</a></li>
                            <li><a href="#" class="hover-style-link">Terms of Payment</a></li>
                            <li><a href="#" class="hover-style-link">Privacy Policy</a></li>
                            <li><a href="#" class="hover-style-link">Where to Find Us</a></li>
                        </ul>
                    </div>
                    <div class="col-lg-2 col-md-4 col-sm-6 footer-widget">
                        <h6 class="footer-widget__title mb-20">Support</h6>
                        <ul class="footer-widget__list">
                            <li><a href="#" class="hover-style-link">Forum Support</a></li>
                            <li><a href="#" class="hover-style-link">Help & FAQ</a></li>
                            <li><a href="#" class="hover-style-link">Contact Us</a></li>
                            <li><a href="#" class="hover-style-link">Pricing and plans</a></li>
                            <li><a href="#" class="hover-style-link">Cookies Policy</a></li>
                        </ul>
                    </div> -->
                    <!-- <div class="col-lg-2 col-md-4 col-sm-6 footer-widget">
                        <div class="footer-widget__title section-space--mb_50"></div>
                        <ul class="footer-widget__list">
                            <li>
                                <a href="#" class="image_btn"><img class="img-fluid" src="capset_assets/images/icons/aeroland-button-google-play.jpg" alt=""></a>
                            </li>
                            <li>
                                <a href="#" class="image_btn"><img class="img-fluid" src="capset_assets/images/icons/aeroland-button-app-store.jpg" alt=""></a>
                            </li>
                        </ul>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="footer-copyright-area section-space--pb_30">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-6 text-center text-md-left">
                        <span class="copyright-text">&copy; 2022 Capset Infotech. <a href="#">All Rights
                Reserved.</a></span>
                    </div>
                    <div class="col-md-6 text-center text-md-right">
                        <ul class="list ht-social-networks solid-rounded-icon">

                            <li class="item">
                                <a href="#" target="_blank" aria-label="Twitter" class="social-link hint--bounce hint--top hint--primary">
                                    <i class="fab fa-twitter link-icon"></i>
                                </a>
                            </li>
                            <li class="item">
                                <a href="#" target="_blank" aria-label="Facebook" class="social-link hint--bounce hint--top hint--primary">
                                    <i class="fab fa-facebook-f link-icon"></i>
                                </a>
                            </li>
                            <li class="item">
                                <a href="#" target="_blank" aria-label="Instagram" class="social-link hint--bounce hint--top hint--primary">
                                    <i class="fab fa-instagram link-icon"></i>
                                </a>
                            </li>
                            <li class="item">
                                <a href="#" target="_blank" aria-label="Linkedin" class="social-link hint--bounce hint--top hint--primary">
                                    <i class="fab fa-linkedin link-icon"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--====================  End of footer area  ====================-->
</div>








<!-- Start Toolbar -->
<div class="demo-option-container">
    <!-- Start Toolbar -->
    <!-- <div class="aeroland__toolbar">
        <div class="inner">
            <a class="quick-option hint--bounce hint--left hint--black primary-color-hover-important" href="#" aria-label="Quick Options">
                <i class="fal fa-project-diagram"></i>
            </a>
            <a class="hint--bounce hint--left hint--black primary-color-hover-important" target="_blank" href="https://hasthemes.com/contact-us/" aria-label="Support Center">
                <i class="fal fa-life-ring"></i>
            </a>
            <a class="hint--bounce hint--left hint--black primary-color-hover-important" target="_blank" href="https://themeforest.net/item/mitech-it-solutions-html-template/24906742?ref=AslamHasib" aria-label="Purchase Mitech">
                <i class="fal fa-shopping-cart"></i>
            </a>
        </div>
    </div> -->
    <!-- End Toolbar -->
    <!-- Start Quick Link -->
    <div class="demo-option-wrapper">
        <div class="demo-panel-header">
            <div class="title">
                <h6 class="heading mt-30">IT Solutions Mitech - Technology, IT Solutions & Services Html5 Template</h6>
            </div>

            <div class="panel-btn mt-20">
                <a class="ht-btn ht-btn-md" href="https://themeforest.net/item/mitech-it-solutions-html-template/24906742?ref=AslamHasib"><i
            class="far fa-shopping-cart mr-2"></i> Buy Now </a>
            </div>
        </div>
        <div class="demo-quick-option-list">
            <a class="link hint--bounce hint--black hint--top hint--dark" href="index-appointment.html" aria-label="Appointment">
                <img class="img-fluid" src="capset_assets/images/demo-images/home-01.jpg" alt="Images">
            </a>
            <a class="link hint--bounce hint--black hint--top hint--dark" href="index-infotechno.html" aria-label="Infotechno">
                <img class="img-fluid" src="capset_assets/images/demo-images/home-02.jpg" alt="Images">
            </a>
            <a class="link hint--bounce hint--black hint--top hint--dark" href="index-processing.html" aria-label="Processing">
                <img class="img-fluid" src="capset_assets/images/demo-images/home-03.jpg" alt="Images">
            </a>
            <a class="link hint--bounce hint--black hint--top hint--dark" href="index-services.html" aria-label="Services">
                <img class="img-fluid" src="capset_assets/images/demo-images/home-04.jpg" alt="Images">
            </a>
            <a class="link hint--bounce hint--black hint--top hint--dark" href="index-resolutions.html" aria-label="Resolutions">
                <img class="img-fluid" src="capset_assets/images/demo-images/home-05.jpg" alt="Images">
            </a>
            <a class="link hint--bounce hint--black hint--top hint--dark" href="index-cybersecurity.html" aria-label="Cybersecurity">
                <img class="img-fluid" src="capset_assets/images/demo-images/home-06.jpg" alt="Images">
            </a>
        </div>
    </div>
</div>
<!-- End Toolbar -->
<!--====================  scroll top ====================-->
<a href="#" class="scroll-top" id="scroll-top">
    <i class="arrow-top fal fa-long-arrow-up"></i>
    <i class="arrow-bottom fal fa-long-arrow-up"></i>
</a>
<!--====================  End of scroll top  ====================-->

<!--====================  mobile menu overlay ====================-->
<div class="mobile-menu-overlay" id="mobile-menu-overlay">
    <div class="mobile-menu-overlay__inner">
        <div class="mobile-menu-overlay__header">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-md-6 col-8">
                        <!-- logo -->
                        <div class="logo">
                            <a href="index.html">
                                <img src="capset_assets/images/logo/Twinnet.png" class="img-fluid" alt="">
                            </a>
                        </div>
                    </div>
                    <div class="col-md-6 col-4">
                        <!-- mobile menu content -->
                        <div class="mobile-menu-content text-right">
                            <span class="mobile-navigation-close-icon" id="mobile-menu-close-trigger"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mobile-menu-overlay__body">
            <nav class="offcanvas-navigation">
                <ul>
                    <li class="has-children">
                        <a href="index.html">Home</a>
                        <ul class="sub-menu">
                            <li><a href="index-infotechno.html"><span>Infotechno</span></a></li>
                            <li><a href="index-processing.html"><span>Processing</span></a></li>
                            <li><a href="index-appointment.html"><span>Appointment</span></a></li>
                            <li><a href="index-services.html"><span>Services</span></a></li>
                            <li><a href="index-resolutions.html"><span>Resolutions</span></a></li>
                            <li><a href="index-cybersecurity.html"><span>cybersecurity</span></a></li>
                        </ul>
                    </li>
                    <li class="has-children">
                        <a href="#">Company</a>
                        <ul class="sub-menu">
                            <li class="has-children">
                                <a href="about-us-01.html"><span>About us</span></a>
                                <ul class="sub-menu">
                                    <li><a href="about-us-01.html"><span>About us 01</span></a></li>
                                    <li><a href="about-us-02.html"><span>About us 02</span></a></li>
                                    <li class="has-children">
                                        <a href="#"><span>Submenu Level Two</span></a>
                                        <ul class="sub-menu">
                                            <li><a href="#"><span>Submenu Level Three</span></a></li>
                                            <li><a href="#"><span>Submenu Level Three</span></a></li>
                                            <li><a href="#"><span>Submenu Level Three</span></a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li><a href="contact-us.html"><span>Contact us</span></a></li>
                            <li><a href="leadership.html"><span>Leadership</span></a></li>
                            <li><a href="why-choose-us.html"><span>Why choose us</span></a></li>
                            <li><a href="our-history.html"><span>Our history</span></a></li>
                            <li><a href="faqs.html"><span>FAQs</span></a></li>
                            <li><a href="careers.html"><span>Careers</span></a></li>
                            <li><a href="pricing-plans.html"><span>Pricing plans</span></a></li>
                        </ul>
                    </li>
                    <li class="has-children">
                        <a href="#">IT solutions</a>
                        <ul class="sub-menu">
                            <li><a href="it-services.html"><span>IT Services</span></a></li>
                            <li><a href="managed-it-service.html"><span>Managed IT Services</span></a></li>
                            <li><a href="industries.html"><span>Industries</span></a></li>
                            <li><a href="business-solution.html"><span>Business solution</span></a></li>
                            <li><a href="it-services-details.html"><span>IT Services Details</span></a></li>
                        </ul>
                    </li>
                    <li class="has-children">
                        <a href="#">Elements</a>
                        <ul class="sub-menu">
                            <li class="has-children">
                                <a href="#">Element Group 01</a>
                                <ul class="sub-menu">
                                    <li><a href="element-accordion.html"><span>Accordions & Toggles</span></a></li>
                                    <li><a href="element-box-icon.html"><span>Box Icon</span></a></li>
                                    <li><a href="element-box-image.html"><span>Box Image</span></a></li>
                                    <li><a href="element-box-large-image.html"><span>Box Large Image</span></a></li>
                                    <li><a href="element-buttons.html"><span>Buttons</span></a></li>
                                    <li><a href="element-cta.html"><span>Call to action</span></a></li>
                                    <li><a href="element-client-logo.html"><span>Client Logo</span></a></li>
                                    <li><a href="element-countdown.html"><span>Countdown</span></a></li>
                                </ul>
                            </li>
                            <li class="has-children">
                                <a href="#">Element Group 02</a>
                                <ul class="sub-menu">
                                    <li><a href="element-counters.html"><span>Counters</span></a></li>
                                    <li><a href="element-dividers.html"><span>Dividers</span></a></li>
                                    <li><a href="element-flexible-image-slider.html"><span>Flexible image slider</span></a></li>
                                    <li><a href="element-google-map.html"><span>Google Map</span></a></li>
                                    <li><a href="element-gradation.html"><span>Gradation</span></a></li>
                                    <li><a href="element-instagram.html"><span>Instagram</span></a></li>
                                    <li><a href="element-lists.html"><span>Lists</span></a></li>
                                    <li><a href="element-message-box.html"><span>Message box</span></a></li>
                                </ul>
                            </li>
                            <li class="has-children">
                                <a href="#">Element Group 03</a>
                                <ul class="sub-menu">
                                    <li><a href="element-popup-video.html"><span>Popup Video</span></a></li>
                                    <li><a href="element-pricing-box.html"><span>Pricing Box</span></a></li>
                                    <li><a href="element-progress-bar.html"><span>Progress Bar</span></a></li>
                                    <li><a href="element-progress-circle.html"><span>Progress Circle</span></a></li>
                                    <li><a href="element-rows-columns.html"><span>Rows & Columns</span></a></li>
                                    <li><a href="element-social-networks.html"><span>Social Networks</span></a></li>
                                    <li><a href="element-tabs.html"><span>Tabs</span></a></li>
                                    <li><a href="element-team-member.html"><span>Team member</span></a></li>
                                </ul>
                            </li>
                            <li class="has-children">
                                <a href="#">Element Group 04</a>
                                <ul class="sub-menu">
                                    <li><a href="element-testimonials.html"><span>Testimonials</span></a></li>
                                    <li><a href="element-timeline.html"><span>Timeline</span></a></li>
                                    <li><a href="element-carousel-sliders.html"><span>Carousel Sliders</span></a></li>
                                    <li><a href="element-typed-text.html"><span>Typed Text</span></a></li>
                                </ul>
                            </li>

                        </ul>
                    </li>
                    <li class="has-children">
                        <a href="javascript:void(0)">Case Studies</a>
                        <ul class="sub-menu">
                            <li><a href="case-studies.html"><span>Case Studies 01</span></a></li>
                            <li><a href="case-studies-02.html"><span>Case Studies 02</span></a></li>
                            <li><a href="single-smart-vision.html"><span>Single Layout</span></a></li>
                        </ul>
                    </li>
                    <li class="has-children">
                        <a href="javascript:void(0)">Blogs</a>
                        <ul class="sub-menu">
                            <li><a href="blog-list-large-image.html"><span>List Large Image</span></a></li>
                            <li><a href="blog-list-left-large-image.html"><span>Left Large Image</span></a></li>
                            <li><a href="blog-grid-classic.html"><span>Grid Classic</span></a></li>
                            <li><a href="blog-grid-masonry.html"><span>Grid Masonry</span></a></li>
                            <li class="has-children">
                                <a href="blog-post-layout-one.html"><span>Single Layouts</span></a>
                                <ul class="sub-menu">
                                    <li><a href="blog-post-layout-one.html"><span>Left Sidebar</span></a></li>
                                    <li><a href="blog-post-right-sidebar.html"><span>Right Sidebar</span></a></li>
                                    <li><a href="blog-post-no-sidebar.html"><span>No Sidebar</span></a></li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</div>
<!--====================  End of mobile menu overlay  ====================-->







<!--====================  search overlay ====================-->
<div class="search-overlay" id="search-overlay">

    <div class="search-overlay__header">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-md-6 ml-auto col-4">
                    <!-- search content -->
                    <div class="search-content text-right">
                        <span class="mobile-navigation-close-icon" id="search-close-trigger"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="search-overlay__inner">
        <div class="search-overlay__body">
            <div class="search-overlay__form">
                <form action="#">
                    <input type="text" placeholder="Search">
                </form>
            </div>
        </div>
    </div>
</div>
<!--====================  End of search overlay  ====================-->