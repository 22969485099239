import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  constructor(public http: HttpClient) { }

  name: any;
  email: any;
  subject: any;
  message: any;
  discription: any;
  response_message: any;
  status: any;
  ngOnInit(): void {
    this.name = "";
    this.email = "";
    this.subject = "";
    this.message = "";
    this.discription = "";
    this.response_message = "";
    this.status = 0;
  }

  contact_form(value: any) {
    console.log(value)
    const maindata = {
      name: this.name,
      email: this.email,
      subject: this.subject,
      discription: this.discription
    };
    // console.log(maindata);
    if (this.name != "" && this.email != "" && this.subject != "") {
      const maindata = {
        name: value.name,
        email: this.email,
        subject: this.subject,
        discription: this.discription
      };
      // http://localhost:8000/twinnet_contact
      this.http.post('http://208.109.8.100:49515/twinnet_contact', maindata).subscribe(
        (res) => {
          this.status = 1;
          this.response_message = 'Inquiry is successfully received. we will reply you as soon as possible.';
          this.name = "";
          this.email = "";
          this.subject = "";
          this.message = "";
          this.discription = "";
        }
      )
    } else {
      this.status = 0;
      this.response_message = "Please Enter Valid Details.";
    }
  }

}
